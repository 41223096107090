// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------
//loop through each color in the $palettes map in style/scss/_variables.scss and add the modifiers
@each $palette in $palettes {
    //Grab/Define the main colors e.g. "colorBrandBlue"
    // $colorName: nth($palette, 1);
    $baseColorName: to-string(nth($palette, 1)); //Grab/Define the modifiers, e.g. "lighter"
    $modifiers: nth($palette, 2); //Generate helper classes
    @each $shade,
    $value in $modifiers {
        //when we find the name "500" we want to remove it from the helper classes
        @if $shade==500 {
            $shade: '';
        } //Generate text color classes
        .color#{capitalize($baseColorName)}#{$shade} {
            color: $value !important;
        } //Generate text color classes
        .fill#{capitalize($baseColorName)}#{$shade} {
            background-color: $value !important;
        }
    }
}
@mixin sideToSide {
    @include noPhatBottom;
    display:flex;
    align-items:center;
    Justify-content:space-between;
    >*, > ul > li {
      margin-bottom:0 !important;
    }
}
.sideToSide {
    @include sideToSide;
}


@include media-breakpoint-up(sm) {
    .sideToSide--breakSm {
        @include sideToSide;
    }
}/*end sm*/

@include media-breakpoint-up(md) {
    .sideToSide--breakMd {
        @include sideToSide;
    }
}/*end med*/

@include media-breakpoint-up(lg) {
    .sideToSide--breakLg {
        @include sideToSide;
    }
}/*end lg*/

.mb-20 {
	margin-bottom: $spacer*1.25 !important;
}
.mb-md-9 {
    @include media-breakpoint-up(md) {
        margin-bottom: 5rem!important;
    }
}

.colorWhite {
	color: $white !important;
}
.align-right{
    text-align: right;
}
.textCenter {
	text-align: center !important;
}
.textUnderline {
    text-decoration: underline !important;
}
.weightBold {
	font-weight: bold !important;
}

.minHeight-auto {
	min-height: auto !important;
}

.capped{
    border-top: 1px solid palette(charcoal,50);
    padding-top: $spacer*4;
    margin: auto;
}

.bottomGradient{
    border: 0;
    border-bottom: 4px solid;
    border-image: linear-gradient(to right, palette(softBlue,400), $navyBlue);
    border-image-slice: 1;
}
.container--sm {
    max-width:775px;
}

.h-fillGray50 {
	background-color: palette(gray,50);
}

.h-filterInvert {
	filter: invert(0.75) !important;
}

.fillSoftWhite {
    background-color: palette(softWhite,50) !important;
}

.fillSoftBlue {
	background-color: palette(softBlue,50) !important;
}

.fillSoftBlue-real {
	background-color: $softBlue;
    a {
        color: #1d1d3f;
    }
}


.colorFlame {
	color: $flame !important;
}

.colorGray {
	color: $gray !important;
}

.colorNavy {
	color: $navyBlue !important;
}

.colorLightGray {
	color: palette(gray, 200) !important;
}

.font-bold {
    font-weight: bold !important;
}

.colorText {
	color: $fontColorBase !important;
}

.sourceSans {
	font-family: 'Montserrat','Helvetica', sans-serif;
}
.fontSize-12 {
	font-size: 12px !important;
}
.fontSize-14 {
	font-size: 14px !important;
}
.fontSize-16 {
	font-size: 16px !important;
}
.fontSize-18 {
	font-size: 18px !important;
}
.fontSize-20 {
	font-size: 20px !important;
}

.video-wrapper-title {
    background: $softBlue;
    text-align: center;
    font-weight: bold;
    padding: 1rem;
    color: $white;
}
.video-wrapper {
    padding: 56.25% 0 0 0;
    position: relative;
    max-width: none;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}





.gutter-md-50.row {
    @include media-breakpoint-up(lg) {
        margin-right: -25px;
        margin-left: -25px;
    }
}
.gutter-md-50 > [class^="col-"], .gutter-2 > [class^=" col-"] {
    @include media-breakpoint-up(lg) {
        padding-right: 25px;
        padding-left: 25px;
    }
}

.layer-up {
    position: relative;
    top: -4rem;
}

.bg--light-gray {
    background-color: #eee;
}