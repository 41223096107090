.author {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 60%;
		background-color: palette(softBlue, 50);
	}
	&-spotlight {
		padding: 2rem;
		background-color: $white;
		border-radius: 7px;
		box-shadow: 0 3px 50px rgba($black,0.2);
		@media (min-width: 992px) {
			padding: 2rem 4rem;
		}
	}
	&-media {
		margin-bottom: 1.875rem;
		@media (min-width: 768px) {
			margin-bottom: 0;
		}
	}
	&-img {
		width: 100%;
	}
	&-name {
		margin-bottom: 5px;
		font-size: 20px;
		font-weight: bold;
	}
	&-title {
		margin-bottom: 18px;
		color: $softBlue;
		font-size: 10px;
		font-weight: bold;
		text-transform: uppercase;
	}
	&-copy {
		margin-bottom: 0;
		font-family: 'Montserrat','Helvetica', sans-serif;
		font-size: 16px;
	}
}