/*
* Styling the pagination plugin jpages
* Currently being used on the filters-example.pug
*/
.jpagination {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    a,
    span {
        // padding:$spacer/4;
        color: inherit;
        min-width: 2em;
        height: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: none;

        &.jp-current {
            border: 4px solid $flame;
        }
    }

    a {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            border-bottom: 3px solid $softBlue;
            width: 21px;
            opacity: 0;
            transition: .5s ease-in-out;
        }

        &:hover {
            &:after {
                opacity: 1;
            }
        }

        &.jp-previous,
        &.jp-current,
        &.jp-next,
        &.jp-hidden,
        &.jp-disabled {
            &:after {
                display: none;
            }
        }
        &.jp-disabled {
            opacity: 0.5;
            pointer-events: none;
            cursor: not-allowed;
        }
    }
}

.jp-previous,
.jp-previous {
    // display:flex;
    // align-items:center;
}

.jp-previous {
    margin-right: $spacer;

    // @extend .fa-arrow-left;
    &:before {
        content: '\f060';
        @extend %iconStyleFas;
        margin-right: $spacer;
        color: $softBlue;
    }
}

.jp-next {
    margin-left: $spacer;

    // @extend .fa-arrow-right;
    &:before {
        content: '\f061';
        @extend %iconStyleFas;
        margin-right: 0;
        margin-left: $spacer;
        color: $softBlue;
        order: 1;
    }
}
