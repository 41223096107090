@media print {
	.headerMain-nav,
	.actions,
	.hero-form,
	.meta,
	.relatedPosts,
	.footerMain,
	.mapFindProviders,
	.hero:after,
	.hero a,
	section img,
	.findProviders-form {
		display: none !important;
	}

	.hero {
		padding: 0 !important;
		background-image: none !important;
		min-height: 0 !important;
		max-width: 1250px !important;
		margin: 20px auto 20px !important;
		* {
			color: #333 !important;
			max-width: none !important;
			margin-right: 0 !important;
		}
	}

	.section {
		padding: 15px 0 0 !important;
	}

	.post {
		width: 100% !important;
		max-width: none !important;
		flex: 0 0 100% !important;

	}
}
