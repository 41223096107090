// -----------------------------------------------------------------------------
// This file contains all styles related to the hero component.
// -----------------------------------------------------------------------------

.hero {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
		width: 100%;
		background-position: center;
    background-repeat: no-repeat;
    z-index: 0;
    background-size: cover;
    justify-content: center;

    @include media-breakpoint-up(md) {
        min-height: 400px;
        max-height: 620px;
    }

    &.section {
        padding: rem(45px) 0;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $navyBlue;
				background: linear-gradient(90deg, rgba($navyBlue,1) 20%, rgba($navyBlue,0.7) 100%);

        z-index: -1;
        // max-height: 400px;

        @include media-breakpoint-up(md) {
            max-height: none;
						background: linear-gradient(90deg, rgba($navyBlue,1) 20%, rgba($navyBlue,0.7) 100%);
        }
		}
		&--extraPadding {
			&.section {
				padding-top: 4rem;
				padding-bottom: 4rem;
				@include media-breakpoint-up(md) {
					padding-top: 6rem;
					padding-bottom: 6rem;
				}
				@include media-breakpoint-up(lg) {
					padding-top: 8rem;
					padding-bottom: 8rem;
				}
			}
		}
    &--speedTest {
        min-height: none;
        max-height: none;
    }
    &--top {
        justify-content: flex-start;
        min-height: 395px;

        @include media-breakpoint-up(md) {
            justify-content: center;
            min-height: none;
        }
    }
    &--center {
        &.hero.section {
            padding-bottom: 0;
        }
        &::after {
            display: none;
        }
        .hero-heading {
            font-style: normal;
            font-weight: 900;
            font-size: 52px;
            line-height: 62px;
            letter-spacing: 0.01em;
            color: $white;
            text-shadow: 0px 4px 50px #1D1D3F;
            text-transform: none;
            @include media-breakpoint-up(md) {
                margin-bottom: 4rem;
                font-size: 62px;
                line-height: 76px;
            }
            @include media-breakpoint-up(lg) {
                margin-bottom: 5rem;
            }
        }
        .hero-content {
            max-width: 100%;
            text-align: center;
        }
        .hero-cta {

        }
        
    }

    &--short {
        justify-content: flex-start;
        // min-height: 240px;

        @include media-breakpoint-up(md) {
            justify-content: center;
            min-height: none;
        }
    }

    &--unavailable {
        &:after {
            max-height: 100%;
        }
        .hero-content {
            @include media-breakpoint-up(md) {
                max-width: none;
            }
        }
        .hero-heading {
            text-align: center;
            @media (min-width: 768px) {
                margin-bottom: 0;
            }
        }
    }
    &--bgNavy {
        background-color: $navyBlue;
		}
		&--cordCutting {
			padding: $spacer*4 0;
			@media (min-width: 768px) {
				padding: $spacer*6 0;
			}
			&:after {
				max-height: 100%;
				background-color: rgba(#31313C, 0.59);
				background-image: none;
			}
			.hero-content {
				max-width: 100%;
			}
			.hero-heading {
				color: $white;
				font-size: 36px;
				text-transform: none;
			}
			.hero-copy {
				margin-bottom: $spacer*2;
				font-size: 16px;
			}
			.hero-playButton {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 72px;
				height: 72px;
				margin: 0 auto $spacer;
				background-color: transparent;
				background-image: url('/img/content-images/play-button.svg');
				background-position: calc(50% + 2px) center;
				background-repeat: no-repeat;
				border: 3px solid $white;
				border-radius: 50%;
				box-shadow: none;
				color: $white;
				font-size: 1.5rem;
				text-align: center;
			}
			.hero-subtitle {
				font-size: 13px;
				font-weight: bold;
				text-transform: uppercase;
			}
		}
}

.hero-inner {
    overflow: visible;
    height: 1000px;
}

.hero-content {
    color: $white;
    display: flex;
		flex-direction: column;
		align-items: flex-start;
    justify-content: center;
    height: 100%;
    width: 100%;
		max-width: none;
		margin-bottom: 40px;

    @include media-breakpoint-up(md) {
				max-width: 85%;
				margin-bottom: 0;
    }
    .hero-cta {
        margin: 0 auto;
    }

    .btn--hero {
			padding-left: 30px;
			padding-right: 30px;
			box-shadow: none;
			margin: 0 auto;
			@include media-breakpoint-up(lg) {
				margin: 0;
				padding-left: 50px;
				padding-right: 50px;
			}
		}

    &--campaign {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
		}
		
		.hero--extraPadding & {
			max-width: none;
		}
}

.hero-return {
		display: block;
		min-width: 100%;
    background: rgba($navyBlue, .85);
    // margin-bottom: $spacer;
    margin-left: -$spacer;
    margin-right: -$spacer;
    padding: $spacer;
    position: relative;
    top: -$spacer*3;
    text-decoration: none;

    @include media-breakpoint-up(md) {
			min-width: auto;
        background: none;
        margin: 0 0 $spacer 0;
        padding: 0;
        top: -$spacer;
    }

    .hero-returnLink {
        color: $white;
        text-decoration: none;
        &:before {
            @extend %iconStyleFas;
            @extend .fa-arrow-left;
            color: $softBlue;
        }
    }

    &--video {
        position: absolute;
        top: $spacer*2;
        padding: $spacer/2 $spacer;
        background-color: rgba($navyBlue, 0.8);
        z-index: 9;
    }
}

.hero-heading {
    width: 100%;
    color: $white;
    text-transform: uppercase;
    font-size: 36px;
    flex: 0 0 auto;
    @include media-breakpoint-up(lg) {
        font-size: 52px;
		}
		.hero--extraPadding & {
			margin-top: 2rem;
			margin-bottom: 2rem;
			font-size: 48px;
			@include media-breakpoint-up(lg) {
				margin-top: 4rem;
				margin-bottom: 1rem;
				font-size: 58px;
			}
		}
}

.hero-copy {
    width: 100%;
    margin-bottom: $spacer;
    font-size: 16px;
    @include media-breakpoint-up(md) {
        margin-bottom: 2rem;
        font-size: 18px;
		}
}

.hero-form {
    .hero-formHeading {
        margin-bottom: $spacer;
    }
}

.mediaLanding {
	height: 850px;
    @media (min-width: 768px) {
		height: 600px;
	}
	@media (min-width: 992px) {
		height: auto;
	}
}

.mediaLandingHero {
		max-width: 2000px;
		max-height: 300px;
		margin: 0 auto;
		padding-top: 2rem;
		padding-bottom: 2rem;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		@media (min-width: 768px) {
			max-height: 400px;
		}
		@media(min-width: 992px) {
			max-height: none;
			padding-top: 4rem;
			padding-bottom: 4rem;
		}
		.hero-form {
			margin-top: 0;
		}
        .hero-content {
            min-height: 200px;
        }
}

.adLandingHero {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
    .hero {
        &-content {
            @include media-breakpoint-up(md) {
                max-width: 95%;
                width: 100%;

                img.d-md-block {
                    margin-top: -35px;
                }
            }
        }

        &-heading {
            @include media-breakpoint-up(md) {
                margin-top: 25px;
            }
        }

        &-copy {
            @include media-breakpoint-up(md) {
                max-width: 79%;
            }
        }
    }
}

.hero-downloadLink {
    color: $white;
    border-bottom: 4px solid $softBlue;
    align-self: flex-start;
    margin-top: 20px;
    text-decoration: none;
}

.hero--video {
    position: relative;
    display: block;
    padding: 0 !important;
    min-height: auto !important;
		height: auto !important;
		background-color: $navyBlue;
}

.hero-content--video {
    position: relative;
    height: auto;
    margin: 0 auto;
    padding: 0;
    max-width: 1280px !important;

    .container {
        padding: 56.25% 0 0 0;
        position: relative;
        max-width: none;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

#video-background {
    width: 100% !important;
    height: auto !important;
}


.hero-breadcrumbs {
    list-style: none;
    padding-left: 0;
    li {
        display: inline-block;
        a {
            //text-decoration: none;
            &:hover {
                text-decoration: none;
            }
        }
        &:after {
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            font-weight: normal;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            margin-right: 4px;
            margin-left: 4px;
            content: "\f105";

        }
        &:last-child {
            &::after{
                display: none;
            }
        }
    }
}

//modifications for the hero on the homepage
.hero--smaller {
    @include media-breakpoint-up(md) {
        min-height: 350px;
    }
    &:after {
        background: $navyBlue;
				background: linear-gradient(90deg, rgba($navyBlue,1) 20%, rgba($navyBlue,0.7) 100%);
        @include media-breakpoint-up(md) {
            max-height: none;
						background: linear-gradient(90deg, rgba($navyBlue,0.8) 20%, rgba($navyBlue,0.2) 100%);
        }
	}
    .hero-heading {
        font-size: 32px;
        @include media-breakpoint-up(lg) {
            font-size: 42px
        }
    }
    .hero-copy {
        margin-bottom: 0.25rem;
    }

}