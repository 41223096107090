.actions {
    display: flex;
    padding: 10px 0;
    border-bottom: 2px dashed #ddd;
    margin-bottom: 20px;
}

.action-item {
    margin: 0 10px;
    display: flex;
    align-items: center;
    text-decoration: none;
    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    @media (min-width: 800px) {
        margin: 0 10px;
    }

    span {
        font-size: .95rem;
        text-decoration: none;
        color: $charcoal;

        @media (min-width: 800px) {
            font-size: 1.25rem;
        }
    }

    i {
        font-size: 1.15rem;
        margin-right: 10px;

        @media (min-width: 800px) {
            font-size: 1.5rem;
        }

        &[class^='fa-'],
        &[class*=' fa-'] {
            &:before {
                margin-right: 0;
            }
        }
    }
}
