.letterSort {
    margin-bottom: 3rem;
}

.letterSort__letters {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
}
a.letterSort__letter {
    padding: .5rem 0.5rem;
    font-weight: bold;
    font-size: 21px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.1em;
    color: $white;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
        color: $flame;
    }
}

.letterSort__note {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: $softBlue;
    text-align: center;
}