// -----------------------------------------------------------------------------
// This file contains all styles related to the providers component.
// -----------------------------------------------------------------------------

.logoList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style-type: none;
    padding: $spacer 0;
    flex-wrap: wrap;
    align-items: center;

    svg {
        max-height: 40px;
        max-width: 100%;

        path,
        polygon,
        polyline {
            fill: $navyBlue;
        }
    }

    a {
        svg {
            transition: all .2s;

            path,
            polygon,
            polyline,
            rect {
                transition: all .2s;
            }
        }

        &:hover {
            svg {
                transition: all .4s;

                path,
                polygon,
                polyline,
                rect {
                    transition: all .4s;
                    fill: #B0B09F;
                }
            }
        }
    }

    .logoList-wrapper--white & {
        svg {
            max-height: 35px;

            path,
            polygon,
            polyline,
            rect {
                fill: white;
            }
        }

        a {
            svg {
                opacity: .6;
            }

            &:hover {
                svg {
                    opacity: 1;

                    path,
                    polygon,
                    polyline,
                    rect {
                        fill: white;
                    }
                }
            }
        }
    }

    .providers-listLink img {
        filter: opacity(1);

        &:hover {
            color: $softBlue;
        }
    }

    .featureTile-item & {
        padding: 0;
    }

    .logoList-wrapper--white & {
        @include media-breakpoint-up(lg) {
            flex-wrap: nowrap;
            overflow: hidden;
        }
    }
}

.logoList-item {
    flex: 0 1 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding: 1.125rem 0.625rem;

    @include media-breakpoint-up(sm) {
        flex: 0 0 auto;
        height: 60px;
        width: 125px;
        padding: 0.5rem;
    }

    @include media-breakpoint-up(md) {
        padding: 0 $spacer*0.75;
    }

    @include media-breakpoint-up(lg) {
        height: 125px;
        margin-left: 0.5rem;
        margin-right: 0.5rem;

        .featureTile-item & {
            flex: 0 0 auto;
            flex-basis: 60px;
            margin: 0 $spacer/2;
            width: 60px;
            height: 65px;
        }
    }

    .logoList-wrapper--white & {
        @include media-breakpoint-up(lg) {
            height: 100px;
            flex: 1 1 auto;

            .logoList-link {
                cursor: pointer;
            }
        }
    }
}

.featureTile-item {
    svg {
        max-height: 20px;
    }
}

/* For logo lists with smaller images that aren't linked */
.logoList--noWrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style-type: none;
    padding: $spacer;
    flex-wrap: wrap;

    @include media-breakpoint-up(lg) {
        flex-wrap: nowrap;
    }

    .logoList-item {
        @include media-breakpoint-up(lg) {
            flex: 1 1 auto;
            height: 70px;
        }
    }
}


.logoList-item--breezeline {
    @include media-breakpoint-up(lg) {
        width: 160px;
    }
}