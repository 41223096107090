.responsiveEmbed {
    position: relative;
    min-width: 100%;

    &::before {
        display: block;
        content: '';
        padding-top: 56.25%; //16:9 aspect ratio, 9/16=.5625
    }

    iframe {
        // can be the stuff inside the wrapper or an iframe or whatever
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        border: none;
    }
}
