:root {
  --bg-color: #F5F9FC;
  --grad-color-1: #00d2ff;
  --grad-color-2: #3a47d5;
  --gradient: linear-gradient(90deg, var(--grad-color-1) 0%, var(--grad-color-2) 100%);
  --shadow: 0 3px 15px -5px rgba(0, 0, 0, 0.5);
}
.brand-label{
  &--small{
    max-height: 400px;
    overflow: hidden;
    border: 1px solid gray;
    padding: 1rem;
    position: relative;
    cursor: pointer;
  }
  &__heading{
    border-bottom: 3px solid $navyBlue;
    font-size: 1.5rem;
    color:$navyBlue;
    background-color: palette(gray, 50);
    @media (min-width: 768px) {
      font-size: 1.5rem;
    }
    &:hover{
      background-color: palette(gray, 100);
    }
    .brand-label--small &{
      background-color: $white;
    }
  }
  &__header{
    border-bottom: 12px solid $navyBlue;
  }
  &__section{
    margin-top: 1rem;
    border-bottom: 6px solid $navyBlue;
    padding-bottom: 1rem;

    &-header{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &-copy{
        flex:0 0 100%;
        font-size: .75rem;
      }
      border-bottom: 1px solid $navyBlue;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }
    &-heading{
      flex: 100%;
      @media (min-width: 768px) {
        flex: unset;
      }
    }
    &-content{
      & + .brand-label__section-content{
        border-top: 1px solid $navyBlue;
        padding-top: 1rem;
      }
    }
    &-copy{
      font-size: .75rem;
    }
  }
  &__footer{
    border-top: 1rem solid $navyBlue;
    padding-top: 1.5rem;
  }
  &__btn{
    position: absolute;
    bottom:0;
    width: 100%;
    left: 0;
  }
  .dataTable{
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
    flex-wrap: wrap;
    .brand-label__section-content-heading{
      margin-bottom: 0;
    }
    thead, th, td{
      background-color: unset;
      color: inherit;
      height: unset !important;
      padding: .5rem !important;
      border:none;
    }
    th, td{
      padding-left: 0 !important;
    }
    tr{
      flex: 100%;
      flex-wrap: wrap;
    }
    td:last-child{
      text-align: left;
      @media (min-width: 768px) {
        text-align: right;
      }
    }
    thead{
      flex: 100%;
      box-shadow: unset;
    }
    tbody{
      flex:100%;
      flex-wrap: wrap;

    }
  }
  .accordion-content{
    .col-lg-4 &{
      padding: .5rem .75rem;
    }
  }
}


