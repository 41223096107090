.mediaSteps {
    list-style: none;
    padding-left: 0;
    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: center;
    }
    .mediaSteps-item {
        position: relative;
        counter-increment: white-numbers;
        padding-top: 100px;
        text-align: center;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 1rem;
        a {
            display: block;
            &:before {
                position: absolute;
                top: 0px;
                left: 50%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                content: counter(white-numbers);
                width: 82px;
                height: 82px;
                margin-left: -41px;
                background-color: $softBlue;
                border-radius: 50%;
                color: $navyBlue;
                font-size: 2.75rem;
                font-weight: bold;
                color: $white;
            }
        }
        
    }
}

.fillSoftBlue-real {
    .mediaSteps {
        .mediaSteps-item {
            a {
                &:before {
                    background-color: $navyBlue;
                }
            }
            
        }
    }
}