// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/*
 * black and white for mixing
 */
$white: #fff !default;
$black: #000 !default;
/*
* Define base brand colors and gray variables here and then add them to the map below
*/
$flame: #f05624;
$softWhite: #fbfaf5;
$softBlue: #59bfe6;
$charcoal: #404040;
$navyBlue: #1d1d3f;
$navyBlue2: #26274a;
$gray: #999;
/*
* Place brand colors and gray in map for shades
* Helper classes are being generated by this sass map in styles/base/_helpers.scss
* as well as populating in pug/styleguide.pug
*/
$palettes: (
	white: (
		500 : $white,
	),
	black: (
		500 : $black,
	),
	flame: (
		50 : scale-color($flame, $lightness: 87%),
		100 : scale-color($flame, $lightness: 69%),
		200 : scale-color($flame, $lightness: 50%),
		300 : scale-color($flame, $lightness: 30%),
		400 : scale-color($flame, $lightness: 15%),
		500 : $flame,
		600 : scale-color($flame, $lightness: -9%),
		700 : scale-color($flame, $lightness: -19%),
		800 : scale-color($flame, $lightness: -29%),
		900 : scale-color($flame, $lightness: -48%),
	),
	softWhite: (
		50 : scale-color($softWhite, $lightness: 87%),
		100 : scale-color($softWhite, $lightness: 69%),
		200 : scale-color($softWhite, $lightness: 50%),
		300 : scale-color($softWhite, $lightness: 30%),
		400 : scale-color($softWhite, $lightness: 15%),
		500 : $softWhite,
		600 : scale-color($softWhite, $lightness: -9%),
		700 : scale-color($softWhite, $lightness: -19%),
		800 : scale-color($softWhite, $lightness: -29%),
		900 : scale-color($softWhite, $lightness: -48%),
	),
	softBlue: (
		50 : scale-color($softBlue, $lightness: 87%),
		100 : scale-color($softBlue, $lightness: 69%),
		200 : scale-color($softBlue, $lightness: 50%),
		300 : scale-color($softBlue, $lightness: 30%),
		400 : scale-color($softBlue, $lightness: 15%),
		500 : $softBlue,
		600 : scale-color($softBlue, $lightness: -9%),
		700 : scale-color($softBlue, $lightness: -19%),
		800 : scale-color($softBlue, $lightness: -29%),
		900 : scale-color($softBlue, $lightness: -48%),
	),
	charcoal: (
		50 : scale-color($charcoal, $lightness: 92%),
		100 : scale-color($charcoal, $lightness: 74%),
		200 : scale-color($charcoal, $lightness: 55%),
		300 : scale-color($charcoal, $lightness: 35%),
		400 : scale-color($charcoal, $lightness: 20%),
		500 : $charcoal,
		600 : scale-color($charcoal, $lightness: -9%),
		700 : scale-color($charcoal, $lightness: -19%),
		800 : scale-color($charcoal, $lightness: -29%),
		900 : scale-color($charcoal, $lightness: -65%),
	),
	navyBlue: (
		50 : scale-color($navyBlue, $lightness: 92%),
		100 : scale-color($navyBlue, $lightness: 74%),
		200 : scale-color($navyBlue, $lightness: 55%),
		300 : scale-color($navyBlue, $lightness: 35%),
		400 : scale-color($navyBlue, $lightness: 20%),
		500 : $navyBlue,
		600 : scale-color($navyBlue, $lightness: -9%),
		700 : scale-color($navyBlue, $lightness: -19%),
		800 : scale-color($navyBlue, $lightness: -29%),
		900 : scale-color($navyBlue, $lightness: -65%),
	),
	navyBlue2: (
		50 : scale-color($navyBlue2, $lightness: 92%),
		100 : scale-color($navyBlue2, $lightness: 74%),
		200 : scale-color($navyBlue2, $lightness: 55%),
		300 : scale-color($navyBlue2, $lightness: 35%),
		400 : scale-color($navyBlue2, $lightness: 20%),
		500 : $navyBlue2,
		600 : scale-color($navyBlue2, $lightness: -9%),
		700 : scale-color($navyBlue2, $lightness: -19%),
		800 : scale-color($navyBlue2, $lightness: -29%),
		900 : scale-color($navyBlue2, $lightness: -65%),
	),
	gray: (
		50 : scale-color($gray, $lightness: 92%),
		100 : scale-color($gray, $lightness: 74%),
		200 : scale-color($gray, $lightness: 55%),
		300 : scale-color($gray, $lightness: 35%),
		400 : scale-color($gray, $lightness: 20%),
		500 : $gray,
		600 : scale-color($gray, $lightness: -9%),
		700 : scale-color($gray, $lightness: -19%),
		800 : scale-color($gray, $lightness: -29%),
		900 : scale-color($gray, $lightness: -65%),
	)
);


$marginBase: 1.5rem;
$fontSizeBase: 18px;
$h1Size: 40px;
$h1HeroSize: 45px;
$h2Size: 36px;
$h3Size: 30px;
$h4Size: 22px;
$h5Size: 20px;
$h6Size: 12px;
$headingsFontFamily: 'Montserrat', Helvetica, Arial, sans-serif;
$headingsFontWeight: 700;
$lineHeightBase: 1.5;
$fontColorBase: map-get(map-get($palettes, charcoal), 900);
$fontWeightBase: 400;
$fontFamilyBase: 'Montserrat', Helvetica, Arial, sans-serif;
$linkColorBase: map-get(map-get($palettes, softBlue), 500);
$linkColorHoverBase: darken($linkColorBase,5);
$buttonWeightBase: 700;
//min-width is mostly just used for the fluid type mixin 
$minWidth: 320px;
$maxWidth: 1300px;

$btn-transition: background-color .5s ease-in-out, border-color .5s ease-in-out, box-shadow .5s ease-in-out !default;

//fontawesome
$fa-font-path:"/fonts" !default;
