.feature-offer{
  display: flex;
  padding: 2rem;
  border: 2px solid palette(gray, 100);
  gap:2rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  &--navy{
    background-color: $navyBlue;
    color: $white;
    overflow: hidden;
    padding: 0;

  }
  &__content{
    li{
      margin-bottom: .5rem;
    }
    flex:100%;
    @media (min-width: 768px) {
      flex: 75%;
    }
    .feature-offer--navy &{
      padding: 2rem;

    }
  }
  &__heading{
    font-size: 1.5rem;
    color: $navyBlue;
    .feature-offer--navy &{
      color: $white;
      text-align: center;
    }
  }
  &__sub-heading{
    .feature-offer--navy &{
      color: $white;
      text-align: center;
    }
  }
  &__cta{
    margin-top: 1.25rem;
    .btn{
      width: 100%;
      padding: .5rem;
    }
    .feature-offer--navy &{
      flex: 100%;
    }
  }
  &__pricing{
    
    .feature-offer--navy &{
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
    }
  }
  &__price{
    display: flex;
    align-items: flex-start;
    position: relative;

    .feature-offer--navy &{
      justify-content: center;
    }
  }
  &__dollar{
    font-size: 5rem;
    font-weight: 900;
    line-height: 1;
  }
  &__cents{
    font-size: 2rem;
    font-weight: 900;
  }
  &__time{
    font-size: 1rem;
    padding-top: 1rem;
  }
  &__timeframe{
    flex:0 0 100%;
    position: absolute;
    bottom: 1rem;
    right:0;
    font-size: .75rem;
  }
  &__wrapper{
    padding:1rem 0 2rem 0;
  }
  &__services{
    display: flex;
    position: relative;
    background-color: $white;
    margin: 1rem -1rem 0;
    &:before{
      background-color: $white;
      content: '';
      position: absolute;
      height: 180%;
      top: -30%;
      width: 125%;
      left:-12.5%;
      z-index: 0;
    }
  }
  &__service{
    flex: 0 0 33%;
    text-align: center;
    color:$navyBlue;
    position: relative;
    z-index: 1;
    &-img{
      width: 65%;
    }
    &:nth-child(even){
      border-right: 1px solid palette(grey, 100);
      border-left: 1px solid palette(grey, 100);
    }
  }
  &__footer{
    display: flex;
    gap:.25rem;
    &:before{
      content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNS40MTciIGhlaWdodD0iMTUuNDE3Ij48cGF0aCBkPSJNOC43NjIuMVYwaDQuODkxYTEuNzY0IDEuNzY0IDAgMDExLjc2NCAxLjc2NHY0Ljg5MWgtLjFsLjA4Mi4wODItOC4xNiA4LjE2M2ExLjc2NCAxLjc2NCAwIDAxLTIuNDk0IDBMLjUxNyAxMC42NzJhMS43NjQgMS43NjQgMCAwMTAtMi40OTRMOC42NzguMDE0em0xLjkxNiA2LjA3OWExLjQyNiAxLjQyNiAwIDEwLTEuNDI2LTEuNDI2IDEuNDI2IDEuNDI2IDAgMDAxLjQyNiAxLjQyNnoiIGZpbGw9IiMwMDk5ZDgiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==");
      margin-left: 0;
      margin-right: 8px;
      margin-top: .15rem;
    }
  }
}