@keyframes fadein {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes heightFade {
    0% {
        opacity: 0;
        max-height: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        max-height: 1000px;
    }
}

@keyframes fadeinBump {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes growLeft {
    0% {
        width:0;
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        width:100%;
        opacity: .85;
    }
}

@keyframes leftToRight{
    0% { opacity: 0; }
    5% { opacity: 0; transform: translateX(-50px); }
    10% { opacity: 1; transform: translateX(0px); }
    25% { opacity: 1; transform: translateX(0px); }
    30% { opacity: 0; transform: translateX(50px); }
    80% { opacity: 0; }
    100% { opacity: 0; }
}
@keyframes bottomsUp {
    0% {
        // opacity: 0;
    }
    50% {
        transform: translateY(50px);
        opacity: 0;
    }
    51% {
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
        filter: grayscale(0);
    }
}

@keyframes grow {
    0% {
        opacity: 0;
        transform: scale(.1);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}


