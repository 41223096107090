.privacyTable {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;

    ul {
        @media (min-width: 768px) {
            margin-left: 0;
        padding-left: 12px;
        }
    }

    &-thead {
        display: none;
        vertical-align: top;

        @media (min-width: 768px) {
            display: table-header-group;
            font-size: 16px;
        }
    }

    &-row {
        display: flex;
        flex-wrap: wrap;

        @media (min-width: 768px) {
            display: table-row;
        }
    }

    &-header {
        flex: 0 0 100%;
        padding: $spacer;
        border: 1px solid #ccc;

        &[scope="colgroup"] {
            border-bottom-width: 5px;
            position: relative;

            &:before {
                content: "";
                display: inline-block;
                width: 7px;
                height: 0.75em;
                border-color: #ebebeb;
                border-width: 0 2px;
                border-style: solid;
                position: absolute;
                top: calc(0.3em + #{$spacer*0.75});
                left: -$spacer*0.75;
            }

            @media (min-width: 768px) {
                font-size: 16px;
                border-bottom-width: 3px;
            }
        }

        &[scope="row"] {
            border-bottom-width: 2px;

            @media (min-width: 768px) {
                border-bottom: 0;
                padding-left: $spacer*1.5;
                font-weight: 400;
            }
        }
    }

    &-cell {
        flex: 0 0 100%;
        position: relative;
        padding: $spacer;
        border: 1px solid #ccc;
        display: flex;
        vertical-align: top;
        flex-direction: column;
        font-size: 14px;

        @media (min-width: 768px) {
            display: table-cell;
            border-bottom: 0;
            text-align: left;
        }
    }

    &-cellTitle {
        padding: $spacer;
        font-size: 16px;
        background-color: #f5f5f5;
        font-weight: bold;
        flex: 0 0 auto;
        margin-bottom: $spacer;
        width: 100%;

        @media (min-width: 768px) {
            display: none;
        }
    }
}
