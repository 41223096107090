// -----------------------------------------------------------------------------
// This file contains all styles for site icons
// -----------------------------------------------------------------------------

.fa,
.fas,
.far,
.fal,
.fab {
    color: $softBlue;
}

.fa-error {
    color: $flame;
}