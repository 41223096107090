.alert {
	position: relative;
	background-color: $navyBlue;
	color: $white;
    display: none;
	&__inner {
		max-width: $maxWidth;
        margin: auto;
	}
	&__close {
		position: absolute;
		top: 0.625rem;
		right: 0.625rem;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 2rem;
		height: 2rem;
		padding: 0;
		background-color: rgba($white,0.3);
		border: 0;
		border-radius: 50%;
		color: $white;
		font-size: 1.5rem;
		transition: 0.25s ease;
		cursor: pointer;
        .fa {
            &:before {
                color: $white;
                font-weight: bold;
                margin-left: 1px;
            }
        }
		&:hover {
			background-color: rgba($white,1);
			color: $blue;
            .fa {
                &:before {
                    color: $navyBlue;;
                    font-weight: bold;
                }
            }
		}
	}
	&__content {
		padding: 1rem;
	}
	&__heading {
		margin-bottom: 0;
		//font-family: $fontFamilyHeadings;
		font-size: 1.5rem;
		font-weight: bold;
		&::before {
			margin-right: 0.75rem;
			font-size: 90%;
		}
	}
	&__message {
		margin-bottom: 1rem;
		font-size: 0.875rem;
		a {
			color: $white;
			text-decoration: underline;
		}
	}
	&__cta {
		display: inline-flex;
		align-items: center;
		color: $white;
		font-weight: bold;
        font-family: "Font Awesome 5 Free";
		&::after {
			content: 'X';
			margin-left: 0.5rem;
			transition: 0.25s;
		}
		&:hover {
			text-decoration: none;
			&::after {
				margin-left: 0.875rem;
			}
		}
	}
}