.iconCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $spacer *0.75 $spacer*2;
    text-align: center;
    &--list {
        text-align: left !important;
        padding-left: $spacer;
        padding-right: $spacer;
		}
		&--center {
			.iconCard-title {
				font-size: 1.125rem;
			}
		}
    &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 61px;
        height: 61px;
        margin-bottom: $spacer*1.25;
        border-radius: 50%;
        
        &--navy {
            background-color: $navyBlue;
        }
        i {
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 20px;
            // height: 20px;
            font-size: 1.5rem;
            text-align: center;
            transform: translateX(4px);
        }
    }
    &-listTitle {
        width: 100%;
        margin-bottom: 1.25rem;
        font-size: 1.125rem;
        font-weight: 600;
        text-align: left !important;
		}
		p {
			width: 100%;
			margin-bottom: 0;
		}
		&-title {
			width: 100%;
		}
}
