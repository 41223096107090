.meta {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}

.meta-item {
    display: flex;
    flex-flow: row wrap;
}

.meta-title {
    text-transform: uppercase;
    font-weight: 700;
    flex: 0 0 100%;
    font-size: .9rem;
    margin: 15px 0 5px;
}

.meta-topic {
    font-size: .9rem;
    margin: 0 10px 10px 0;
    flex: 0 0 auto;
    font-weight: 700;
    color: palette(charcoal, 700);
    text-decoration: underline;
}

.meta-tag {
    font-size: .9rem;
    margin: 0 10px 10px 0;
    flex: 0 0 auto;
    text-decoration: none;
    padding: 3px 5px;
    background: $navyBlue;
    color: $white;
}

.meta-social {
    flex: 0 0 auto;
    margin: 0 15px 10px 0;

    &.meta-social--wider {
        margin-right: 40px;

        @include media-breakpoint-up(md) {
            margin-right: 30px;
        }
    }

    i {
        font-size: 1.5rem;

        &[class^='fa-'],
        &[class*=' fa-'] {
            transition: color .3s;

            &:before {
                margin-right: 0;
            }

            &:hover,
            &:focus {
                color: palette(softBlue,300);
            }
        }
    }
}