$filterHeight: 45px;

.filterHidden {
	display: none !important;
}

.filter.is-active {
	box-shadow: 0 0 5px rgb(89, 191, 230);
	border: 1px solid rgb(89, 191, 230);
	&:after {
		transform: rotate(135deg);
		top: 1.15rem;
	}
	.filter-dropdown {
		visibility: visible;
		margin-top: 0;
		z-index: 1;
		pointer-events: all;
		opacity: 1;
	}
}


.filter {
	border: 1px solid rgb(209, 209, 209);
	padding-top: $filterHeight;
	position: relative;
	width: 320px;
	max-width: 100%;
	cursor: pointer;
	z-index: 1;
	background: #fff;

	@media (min-width: 850px) {
		border: none;
		box-shadow: none;
		padding-top: 0;
		width: 100%;
		&:before,
		&:after {
			display: none;
		}
		&.is-active {
			border: none;
			box-shadow: none;
		}
	}

	&:before {
		content: 'Filter: ';
		position: absolute;
		top: 0;
		left: .75rem;
		height: $filterHeight;
		line-height: $filterHeight;
		font-size: .875rem;
		text-transform: uppercase;
		font-weight: 700;
	}
	&:after {
		content: '';
		height: .65rem;
		width: .65rem;
		border-bottom: 2px solid rgb(89, 191, 230);
		border-left: 2px solid rgb(89, 191, 230);
		transform: rotate(-45deg);
		transition: .25s ease-out;
		position: absolute;
		right: 1rem;
		top: 1rem;
	}
}

.filter-selection {
	position: absolute;
	top: 0;
	left: 4.25rem;
	height: $filterHeight;
	line-height: $filterHeight;
	font-size: .875rem;
	text-transform: uppercase;
	font-weight: 700;
	right: 2.5rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	@media (min-width: 850px) {
		display: none;
	}
}

.filter-dropdown {
	position: absolute;
	left: -1px;
	right: -1px;
	padding-top: 5px;
	overflow: hidden;
	visibility: hidden;
	margin-top: -1rem;
	transition: .25s ease-out;
	z-index: -1;
	opacity: 0;
	pointer-events: none;

	@media (min-width: 850px) {
		display: flex;
		flex-flow: row wrap;
		position: static;
		pointer-events: all;
		opacity: 1;
		z-index: 1;
		margin-top: 0;
		padding-top: 0;
		visibility: visible;
	}
}

.filter-item {
	left: 0;
	right: 0;
	height: $filterHeight;
	line-height: $filterHeight;
	flex: 0 0 $filterHeight;
	padding: 0 1rem;
	width: 100%;
	align-items: center;
	font-size: .875rem;
	background: #fff;
	border-left: 1px solid rgb(209, 209, 209);
	border-right: 1px solid rgb(209, 209, 209);
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	transition: .25s ease-out;
	&:hover {
		background: #eee;
	}
	&:first-child {
		border-top: 1px solid rgb(209, 209, 209);
	}
	&:last-child {
		border-bottom: 1px solid rgb(209, 209, 209);
	}
	&--selected {
		color: #bbb;
		&:hover {
			background: #fff;
		}
		&:before,
		&:after {
			content: '\2013';
		}
		&:before {
			padding-right: 3px;
		}
		&:after {
			padding-left: 3px;
		}
	}
	@media (min-width: 850px) {
		flex: 0 0 auto;
		height: auto;
		padding: .75rem 1.5rem;
		line-height: 1;
		width: auto;
		border: 4px solid rgb(29, 29, 63);
		margin-right: 1rem;
		margin-bottom: 1rem;
		text-transform: uppercase;
		font-weight: 700;
		// &:hover {
		// 	background: #fff;
		// }
		&:first-child {
			border-top: 5px solid rgb(29, 29, 63);
		}
		&:last-child {
			border-bottom: 5px solid rgb(29, 29, 63);
		}
		&--selected {
			color: #fff;
			background: rgb(29, 29, 63);
			font-weight: 900;
			&:hover {
				background: rgb(29, 29, 63);
			}
			&:before,
			&:after {
				display: none;
			}
		}
	}
}



[data-filter-id]  {
	padding-bottom: 2rem;
	.mediaTile {
		margin: 0;
		// border: 7px solid #d1d1d1;
		&:hover {
			border: 7px solid #d1d1d1;
		}
	}
}