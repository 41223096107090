.speedTest {
    height: calc(100% - 15px);
    font-family: $headingsFontFamily;
    &--tips {
			height: 100%;
			padding: $spacer*2.5;
			&.hide {
					display: none;
			}
    }
    &--slow,
    &--average,
    &--fast,
    &--xFast{
        position: absolute;
        // display: none;
        padding: $spacer*2.5;
        background-color: rgba($white,0.2);
        opacity: 0;
        transform: translateX(100%);
        visibility: hidden;
        transition: 0.25s;
        overflow: hidden;
        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 5px;
            height: 100%;
        }
        &.show {
            position: relative;
            opacity: 1;
            transform: translateX(0%);
            visibility: visible;
        }
    }
    &--slow {
        &:before {
            background: linear-gradient(0deg, $softBlue 0%, #9b59b6 100%);
        }
        .speedTest-list--checks {
            .speedTest-listItem {
                &:before {
                    background-color: $softBlue;
                }
            }
        }
    }
    &--average {
        &:before {
            background: linear-gradient(0deg, $softBlue 0%, #9b59b6 100%);
        }
        .speedTest-list--checks {
            .speedTest-listItem {
                &:before {
                    background-color: $softBlue;
                }
            }
        }
    }
    &--fast {
        &:before {
            background: linear-gradient(0deg, $flame 0%, $softBlue 100%);
        }
        .speedTest-list--checks {
            .speedTest-listItem {
                &:before {
                    background-color: $flame;
                }
            }
        }
    }
    &--xFast {
        &:before {
            background: linear-gradient(0deg, #f1c40f 0%, $flame 100%);
        }
        .speedTest-list--checks {
            .speedTest-listItem {
                &:before {
                    background-color: #f1c40f;
                }
            }
        }
    }
    iframe {
        max-width: 100%;
		}
		&-provider {
			svg {
				max-width: 120px;
				fill: $white;
			}
		}
    &-title {
        position: relative;
        color: $white;
        font-weight: 300;
        font-size: 36px;
    }
    &-preHeader {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1px;;
        text-transform: uppercase;
    }
    &-heading {
        position: relative;
        color: $white;
        font-weight: 300;
    }
    &-content {
        font-weight: 300;
    }
    &-list {
        position: relative;
        margin-bottom: 0;
        list-style: none;
        counter-reset: white-numbers;
        .speedTest-listItem {
            position: relative;
            counter-increment: white-numbers;
            padding: $spacer*0.25 0;
            font-weight: 300;
            &:before {
                position: absolute;
                top: 8px;
                left: -40px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                content: counter(white-numbers);
                width: 22px;
                height: 22px;
                background-color: $white;
                border-radius: 50%;
                color: $navyBlue;
            }
        }
        &--checks {
            counter-reset: orange-checks;
            margin-bottom: $spacer*1.5;
            .speedTest-listItem {
                counter-increment: orange-checks;
                // @for $i from 1 through 8 {
                //     &:nth-of-type(#{$i}) {
                //         &:before {
                //             background-color: hsla(($i*7), 50%, 50%, 1);
                //         }
                //     }
                // }
                &:before {
                    content: '\f00c';
                    font-family: 'Font Awesome 5 Free';
                    font-size: 0.75rem;
                    font-weight: 900;
                    color: $white;
                    // background-color: $flame;
                }
            }
        }
        &--bullets { 
            .speedTest-listItem {
                &:before {
                    content: '';
                    background-color: orange;
                    width: 0.70rem;
                    height: 0.75rem;
                    top: 11px;;
                }
            }
            
        }
    }
}


.background-split--no-border {
    border: none;
}