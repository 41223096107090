// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */

html {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: $fontFamilyBase;
    font-weight: $fontWeightBase;
    color: $fontColorBase;
    font-size:$fontSizeBase;
		line-height:$lineHeightBase;
		// overflow-x: hidden;
    &.noScroll {
        height: 100vh;
        overflow: hidden;
    }
}

body,
html {
    height: 100%;
}


/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */

*,
*::before,
*::after {
    box-sizing: inherit;
}

img {
	display:inline-block;
    max-width: 100%;
}

.container{
    max-width: $maxWidth;
}

*:hover{
    transition: .5s ease-in-out;
}

.pageWrap {
	overflow-x: hidden;
}
