.speedCard {
		margin-bottom: $spacer;
    padding: $spacer*1.5 $spacer*1.5 $spacer*2;
    background-color: $white;
		border: 5px solid $flame;
		@include media-breakpoint-up(lg) {
			height: 100%;
		}
    &-inner {
        display: flex;
        flex-direction: column;
    }
    &-icon {
				position: relative;
				display: flex;
				flex: 1 0 102px;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin-bottom: $spacer*1.5;
				padding-top: $spacer;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        &--25 {
            background-image: url('/img/content-images/speed-icon-25.svg');
        }
        &--50 {
            background-image: url('/img/content-images/speed-icon-50.svg');
        }
        &--75 {
            background-image: url('/img/content-images/speed-icon-75.svg');
        }
        &--100 {
            background-image: url('/img/content-images/speed-icon-150.svg');
        }
		}
		&-speed {
			font-size: 2rem;
			line-height: 1;
		}
		&-label {
			font-size: 13px;
		}
    &-title {
				margin-bottom: $spacer*0.5;
				font-size: 17px;
    }
    &-list {
        margin: 0;
        padding-left: 20px;
        line-height: 26px;
    }
}

.speedCard-logo {
    height: 30px;
    margin-bottom: 1rem;
    svg {
        height: 100%;
    }
}

.speedCard-cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    .btn {
        margin-bottom: 1rem;
    }
    .btn, .link-text {
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
    }
}