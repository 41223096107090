.contentCarousel {
    &-media {
        margin-bottom: 20px;

        @media (min-width: 992px) {
            margin-bottom: 0;
        }
    }

    &-content {
        margin-bottom: 2rem;
    }

    &-title {
        position: relative;
        color: inherit;
        font-size: 28px;
        font-weight: bold;

        @media (min-width: 576px) {
            font-size: 32px;
        }

        i {
            top: 2px;

            @media (min-width: 992px) {
                position: absolute;
                transform: translateX(-100%);
            }
        }
    }

    &-copy {
        font-family: 'Montserrat','Helvetica', sans-serif;
        font-size: 16px;
    }

    &-list {
        display: block;
        margin: 0 0 20px 0;
        padding: 0;
        font-size: 16px;
        list-style: none;
        //counter-reset: numbers-counter;
    }

    &-listItem {
        display: block;
        margin-bottom: 5px;

        //counter-increment: numbers-counter;
        &:before {
            //content: counter(numbers-counter);
            content: '';
            background-color: $navyBlue;
            display: inline-flex;
            width: 10px;
            height: 10px;
            margin-right: 10px;
            border-radius: 50%;
            color: $navyBlue;
            position: relative;
            top: 0;
        }

        &.is-active {
            font-weight: bold;

            &:before {
                background-color: $softBlue;
                color: $white;
            }
        }

        a {
            display: inline-flex;
            align-items: center;
            color: inherit;
            font-size: 12px;
            text-decoration: none;

            @media (min-width: 576px) {
                font-size: 14px;
            }

            @media (min-width: 992px) {
                font-size: 16px;
            }

            &:hover {
                color: $softBlue;
            }

            span {
                display: inline-block;
                margin-left: 5px;
                font-size: 10px;
                font-weight: 300;
                color: rgba($white, 0.7);
                text-decoration: none;

                .contentCarousel--alt & {
                    color: rgba($navyBlue, 0.7);
                }

                @media (min-width: 576px) {
                    font-size: 12px;
                }
            }
        }
    }

    &-cta {
        font-family: 'Montserrat','Helvetica', sans-serif;
        font-size: 16px;
        text-decoration: none;

        &:after {
            content: '';
            display: inline-block;
            width: 8px;
            height: 8px;
            margin-left: 5px;
            border-right: 1px solid $softBlue;
            border-top: 1px solid $softBlue;
            transform: rotate(45deg) translateY(-2px);
        }
    }



    // swiper styles
    &-video {
        position: relative;
        &::after {
            display: block;
            content: '';
            padding-top: 56.25%;
            pointer-events: none;
        }

        &>* {
            position: absolute;
            top: 0;
            left: 0;
        }

        iframe,
        video {
            width: 100%;
            height: 100%;
        }
    }

    .swiper-button-next.swiper-button-white,
    .swiper-button-prev.swiper-button-white {
        font-size: 20px !important;
        color: $white !important;
    }
}

// Legacy Styles for IE11
@media all and (-ms-high-contrast:none) {

    .swiper-button-prev,
    .swiper-button-next {
        position: absolute;
        top: 50%;
        width: 27px;
        height: 44px;
        margin-top: -22px;
        z-index: 10;
        cursor: pointer;
        background-size: 27px 44px;
        background-position: center;
        background-repeat: no-repeat;
    }

    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
        opacity: 0.35;
        cursor: auto;
        pointer-events: none;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
        left: 10px;
        right: auto;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
        right: 10px;
        left: auto;
    }

    .swiper-button-prev.swiper-button-white,
    .swiper-container-rtl .swiper-button-next.swiper-button-white {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
    }

    .swiper-button-next.swiper-button-white,
    .swiper-container-rtl .swiper-button-prev.swiper-button-white {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
    }

    .swiper-button-prev.swiper-button-black,
    .swiper-container-rtl .swiper-button-next.swiper-button-black {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
    }

    .swiper-button-next.swiper-button-black,
    .swiper-container-rtl .swiper-button-prev.swiper-button-black {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
    }

    .swiper-button-lock {
        display: none;
    }
}


.contentCarousel {
    .swiper-slide {
        @media (min-width: 768px) {

            // hack to get BS grid to work inside a slide
            .col-md-6 {
                flex: 0 0 47%;
                max-width: 47%;
            }
        }

    }

    .swiper-pagination-bullet {
        width: 20px;
        height: 20px;
    }

    .swiper-pagination-bullet-active {}

    .swiper-pagination {
        text-align: left;
        position: relative;
    }
}

.contentCarousel.fillNavyBlue {
    color: $white;

    h1,
    h2,
    h3 {
        color: $white;
    }

    .swiper-pagination-bullet {
        background-color: $white;
    }

    .swiper-pagination-bullet-active {
        background-color: $softBlue;
    }

    .mediaSteps-item {
        a {
            text-decoration: none;

            p {
                color: $white;
                text-decoration: none;
            }
        }
    }

    .contentCarousel-listItem {
        &:before {
            background-color: $white;
        }
    }
}

.contentCarousel.fillSoftBlue-real {

    .swiper-pagination-bullet {
        background-color: $white;
    }

    .swiper-pagination-bullet-active {
        background-color: $white;
    }

    .mediaSteps-item {
        a {
            text-decoration: none;

            p {
                color: $navyBlue;
                text-decoration: none;
            }
        }
    }

    .contentCarousel-listItem {
        &:before {
            background-color: $navyBlue;
        }
    }
    .circle-number, .mediaSteps-item {
        &::before {
            background-color: $navyBlue;
        }
        
    }
}

.circle-number {
    margin-right: 2rem;
    position: relative;
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    color: $white;
    &:before {
        content: '';
        background-color: $softBlue;
        border-radius: 50%;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        top: 0%;
        left: 0%;
        
    }
}

.contentCarousel-row {
    @media (min-width: 768px) {
        display: flex;
    }
}

.contentCarousel-col-md-6 {
    @media (min-width: 768px) {
        width: 50%;
        &:last-child {
            margin-left: 1rem;
        }
    }
}

.contentCarousel.fillSoftBlue-real {
    a.contentCarousel-cta {
        color: #1d1d3f;
        &:after {
            border-color: #1d1d3f;
        }
        &:hover {
            color: #0e0e2d;
        }
        
    }

}

.swiper-container {
    overflow: hidden;
}