// -----------------------------------------------------------------------------
// This file contains all styles related to the featured list component.
// -----------------------------------------------------------------------------


.featureBlock-item{
    padding: $spacer $spacer*0.5;
    color: $navyBlue;
    display: flex;
    flex-direction: row;
    // justify-content: center;
    // align-content: center;
    margin-bottom: 1rem;
    .featureBlock-icon {
        font-size: 1.5rem;
        color: $softBlue;
    }

    .featureBlock-heading {
        font-size: 1rem;
        padding-left: $spacer/2;
        margin: auto 0;
        a {
            text-decoration: none;
            color: $navyBlue;
            &:hover {
                color: $softBlue;
            }
        }
    }
}
