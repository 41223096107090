.service {
    width: calc(625px + #{$spacer*2});
    margin-left: auto;
    margin-right: auto;
    padding: $spacer*2 $spacer;
}

.service-logos {
    padding-top: $spacer;
}

.service-logosHeading {
    text-align: center;
    color: $flame;
    text-transform: uppercase;
    font-size: 22px;
    margin-bottom: $spacer;
}

.service-logosGrid {
    display: flex;
    flex-wrap: wrap;
}

.service-logosItem {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    position: relative;
    border: 5px solid transparent;

    &:after {
        content: '';
        display: block;
        padding-bottom: 60%;
    }

    &:hover {
        border-color: palette(gray, 100);
    }
}

.service-logosItem {
    svg {
        width: 100%;
        max-width: 125px;
        max-height: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.service-company {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: $spacer/2;
    margin-bottom: $spacer/2;
}

.service-companyLogo {
    width: 80px;
}

.service-companyCopy {
    margin-bottom: 0;
    line-height: 1.25;
    font-size: 14px;
    color: palette(gray, 700);
    font-style: italic;
    padding-left: $spacer/2;
}

.service-companyLInk {
    display: block;
    color: palette(gray, 900);
    text-decoration: underline;
    font-weight: bold;
    font-style: normal;
}

.service-box {
    border: 2px solid palette(gray, 100);
    padding: $spacer*2;
    position: relative;
    min-height: 425px;
    line-height: 1.4;
}

.service-header {
    position: relative;
    padding-left: 45px;
    margin-bottom: $marginBase;

}

.service-headerImg {
    width: 34px;
    position: absolute;
    left: 0;
    top: 0;
}

.service-heading {
    font-size: 30px;
    margin-bottom: 0;
}

.service-headingSub {
    font-size: 13px;
}

.service-content {
    padding-top: $spacer;
    padding-bottom: $spacer;
    text-align: center;
    position: relative;
    min-height: 225px;
    display: flex;
    align-items: center;
    flex-direction: column;
    &--narrow {
        width: 55%;
        max-width: 55%;
        margin: 0 auto;
    }
}

.service-contentItem {
    max-width: 55%;
    margin: 0 auto;
}

.service-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $white;
    max-height: 0;
    overflow: hidden;
    font-size: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-left: $spacer;
    padding-right: $spacer;

    >p,
    >ul,
    >ol {
        margin-bottom: $spacer*.75;
        width: 100%;
    }

    >*:last-child {
        margin-bottom: 0;
    }

    .overlay-visible & {
        max-height: none;
        // padding-top: $spacer;
        // padding-bottom: $spacer;
    }

    .overlay-opening & {
        animation: overlay .5s ease;
    }
}

@keyframes overlay {
    0% {
        background-color: $softBlue;
    }

    100% {
        background-color: $white;
    }
}

.service-separator {
    // margin-top: $spacer;
    margin-bottom: $spacer;
    border: 0;
    border-top: 2px dotted;
    width: 100%;

    &:after {
        display: none;
    }
}

.service-infoList {
    list-style: none;
    padding: 0;
    display: flex;
}

.service-infoItem {
    padding-right: $spacer*2;
    font-size: 13px;

    &:last-child {
        padding-right: 0;
    }
}

.service-infoHeading {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: $spacer/2;
}

.service-subHeading {
    font-size: 17px;
    margin-bottom: $spacer*2;
}

.service-input {
    text-align: left;
    margin-bottom: $spacer;

    .has-error {
        color: $flame;

        +label {
            color: $flame;
        }

    }
}

.service-note {
    font-size: 13px;
}

.service-providerList {
    list-style: none;
    padding: 0;
    font-size: 15px;
    display: flex;
    justify-content: center;
    margin-right: -$spacer;
    margin-left: -$spacer;
    margin-bottom: 0;
    width: 100%;
}

.service-providerItem {
    flex: 0 0 55%;
    max-width: 55%;
    position: relative;
    
    >*:last-child {
        margin-bottom: 0;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100px;
        border-right: 1px solid palette(gray, 200);
    }

    &:first-child {
        &:after {
            display: none;
        }
    }
}

.service-providerFigure {
    margin-bottom: 0;
    width: 100%;
}

.service-providerImg {
    max-width: 150px;
    max-height: 45px;
}

.service-providerHeading {
    font-size: inherit;
    margin-bottom: $spacer*1.5;
}

.service-providerCopy {
    font-size: 16px;
    color: $navyBlue;
}

.service-providerSmsMessage {
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-providerSmsMessageFigure {
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 7px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    width: 37px;
    height: 37px;
    min-width: 37px;
}
.service-providerSmsMessageCopy {
    color: $navyBlue;
    font-weight: 400;
    text-align: left;
    margin: 0;
    font-size: 14px;
    flex: 0 1 80%;
}

.service-footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: $spacer/4;
    text-align: center;
}

.service-toggle {
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
}

.service-reset {
    position: absolute;
    right: $spacer/2;
    color: inherit;
    @extend .fa-sync-alt;

    &:before {
        @extend %iconStyleFas;
        color: $softBlue;
        margin-right: $spacer/2;
    }
}

.service-anchorLinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: $spacer*6 0 0 0;
}

.service-anchorLink {
    display: inline-block;
    margin-bottom: $spacer*2;
    color: $navyBlue;
    text-decoration: underline;
    font-weight: 600;
    flex: 0 0 25%;
    
    &:hover {
        color: $navyBlue;
        text-decoration: underline;
    }
}

.service-label {
    display: inline-block;
    color: $navyBlue;
    margin-top: $spacer*10;
    margin-bottom: $spacer/2;
}

.service-smsMessageLink {
    text-decoration: underline;
    cursor: pointer;
}
