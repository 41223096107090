.movie-list {

}
.movie-list__details {
    margin-bottom: 1.5rem;
    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-between;
        //margin-bottom: 2rem;
    }
}

.movie-list__cards {

}

.movie-card {
    display: flex;
    background-color: $white;
    padding: 0.75rem;
    border-radius: 6px;
    margin-bottom: 1rem;
    &__media {
        margin-right: 1rem;
        width: 80px;
        @include media-breakpoint-up(md) {
            width: 140px;
        }
        @include media-breakpoint-up(lg) {
            width: 160px;
        }

    }
    &__content {
        width: 100%;
    }
    &__title {
        font-weight: 500;
        font-size: 1.5rem;
        margin-bottom: 0.75rem;

    }
    &__details {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

    }
    &__rating {
        border: 1px solid $gray;
        padding: 0 0.25rem;
        border-radius: 4px;
        color: $charcoal;
        margin-bottom: 0;
        font-weight: bold;
        font-size: 0.75rem;
        margin-right: 0.75rem;
    }
    &__release {
        font-size: 0.75rem;
        margin-right: 0.75rem;
        color: $charcoal;
        font-weight: bold;
    }
    &__length {
        font-size: 0.75rem;
        white-space: nowrap;
        margin-right: 0.75rem;
        color: $charcoal;
        font-weight: bold;
    }
    &__category {
        font-size: 0.75rem;
        margin-right: 0.75rem;
        color: $charcoal;
        font-weight: bold;
    }
    &__review {
        font-size: 0.875rem;
        display: flex;
        align-items: center;
        margin: 0.5rem 0 ;
        
        @include media-breakpoint-up(md) {
            flex: 1;
            justify-content: flex-end;
        }
    }
    &__review-link {
        margin-right: 0.5rem;
        display: block;
        &:hover {
            box-shadow: 0px 0px 5px 0px rgba(89,191,230,0.75);
        }
    }
    &__review-logo {
        width: 17px;
        height: 17px;
        margin-top: -3px;

    }
    &__review-star {

    }
    &__review-rating {
        font-weight: bold;
    }
    &__review-rating-total {

    }
    &__copy {
        font-size: 0.875rem;
        margin-top: 1rem;
        color: $charcoal;

    }
    &__services {
        @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
    }
    &__services-title {
        margin-bottom: 0;
        margin-right: 1rem;
        margin: 0.5rem 1rem 0.5rem 0 ;
        text-transform: none;
        font-weight: 600;

    }
    &__services-list {
        a { 
            text-decoration: none;
        }

    }
    &__services-image {

        margin: 0.25rem 0.5rem 0.25rem 0;
    }
}

.forward-link {
    text-decoration: none;
    display: block;
    &:after {
        content: '\f061';
        @extend %iconStyleFas;
        margin-right: 0;
        margin-left: $spacer;
        color: $softBlue;
        order: 1;
    }
}

.movie-filters {
    background: $white;
    border-radius: 6px;
    padding: 1rem;
    margin-bottom: 1rem;

    &__cta{
        margin-top: 1rem;
    }

}
.movie-filters__title {
    border-bottom: 1px solid $gray;
    font-size: 1rem;

}
.movie-filters__list {
    list-style: none;
    padding: 0;
    margin: 0;

}
.movie-filters__item {

}
.movie-filters__link {

}

.form-check-radio {
    position: absolute;
    margin-top: 0.4rem;
}

.reelgood-credit__image {
    width: 90px;
}

.reelgood-credit {

}
.reelgood-credet__link {
    color: $gray;
    text-decoration: none;
}