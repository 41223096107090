.howTo {
	display: flex;
	flex-flow: row wrap;
}

.howTo-item {
	display: flex;
	flex-direction: column;
	flex: 0 0 100%;
	padding: 1.25rem;
	margin: 0 0 15px;
	@media (min-width: 719px) {
		flex: 0 0 50%;
	}
	@media (min-width: 991px) {
		flex: 0 0 33%;
	}
}

.howTo-image {
	width: 100%;
	img {
		width: 100%;
	}
}

.howTo-title {
	color: palette(flame, 500);
	text-transform: uppercase;
	margin-top: 10px;
	font-weight: 900;
	font-size: 24px;
}

.howTo-link {
	align-items: center;
	width: auto;
	margin-top: auto;
	margin-right: auto;

	&:hover {
		text-decoration: none;
		color: inherit;
	}
}