// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
    @if $self {

        &,
        &:hover,
        &:active,
        &:focus {
            @content;
        }
    }

    @else {

        &:hover,
        &:active,
        &:focus {
            @content;
        }
    }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
    #{$context} & {
        @content;
    }
}

//mixin for use in media queries or anyplace else extending doesnt make sense
@mixin noPhatBottom {

    &>*:last-child,
    &>*:last-child>*:last-child,
    &>*:last-child>*:last-child>*:last-child {
        margin-bottom: 0 !important;
    }
}

//for extending
%noPhatBottom {
    @include noPhatBottom;
}

//create an iconstyle mixin for fontawesome so you can add icons without an i or span element:
// <p class="fa-heart">Now I have an icon without a clunky i element yo</p>

@mixin iconStyleBase {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    margin-right: .4em;
    font-weight: normal;
}

//normal
%iconStyle {
    @include iconStyleBase;
    font-family: "Font Awesome 5 Free";
}

//solid
%iconStyleFas {
    @include iconStyleBase;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

//brands
%iconStyleFab {
    @include iconStyleBase;
    font-family: "Font Awesome 5 Brands";
    font-weight: 400;
}

[class^='fa-'],
[class*=' fa-'] {

    &.fa,
    &.fas,
    &.far,
    &.fal,
    &.fab {
        font-family: inherit;
        font-weight: inherit;
    }

    &:before {
        @extend %iconStyle;
        text-decoration: none;
    }

    &.fas {
        font-family: inherit;

        &:before {
            @extend %iconStyleFas;
        }
    }

    &.fab {
        font-family: inherit;

        &:before {
            @extend %iconStyleFab;
        }
    }
}

//remove the margin when using the i element since there would most likely be a space used
i[class^='fa-'],
i[class*=' fa-'],
*:empty {
    &:before {
        margin-right: 0;
    }
}

// allow easy nesting for some tricky mixins
@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}

// create linear gradients
@mixin linear-gradient($direction, $color-stops...) {
    @if is-direction($direction)==false {
        $color-stops: ($direction, $color-stops);
        $direction: 180deg;
    }

    background: nth(nth($color-stops, 1), 1);
    background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
    background: linear-gradient($direction, $color-stops);
}

// font-size in rem w/ px fallback
@mixin font-size($size) {
    font-size: $size; //Fallback in px
    font-size: rem($size);
}

// input placeholder mixin 
@mixin placeholder {
    @include optional-at-root('::-webkit-input-placeholder') {
        @content;
    }

    @include optional-at-root(':-moz-placeholder') {
        @content;
    }

    @include optional-at-root('::-moz-placeholder') {
        @content;
    }

    @include optional-at-root(':-ms-input-placeholder') {
        @content;
    }
}