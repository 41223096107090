.mobile-anchorLinks {
	@media (min-width: 1025px) {
		display: none;
	}
}
.dropdown {
	
	&.show {
		.dropdown-toggle {
			color: $black;
			border: 1px solid $softBlue;
			&:before,
			&:after {
				top: calc(50% - 3px);
			}
			&:before {
				transform: rotate(45deg) translateX(-3px);
			}
			&:after {
				transform: rotate(-45deg) translateX(3px);
			}
		}
		.dropdown-menu {
			top: -1px !important;
		}
	}
	&-toggle {
		display: block;
		padding: 10px;
		background-color: $white;
		border: 1px solid palette(gray,300);
		color: $black;
		font-size: 14px;
		text-transform: uppercase;
		&:before,
		&:after {
			position: absolute;
			content: '';
			top: calc(50% - 6px);
			right: 20px;
			width: 2px;
			height: 8px;
			background-color: $softBlue;
			border: none;
		}
		&:before {
			transform: rotate(-45deg) translateX(-3px);
		}
		&:after {
			transform: rotate(45deg) translateX(3px);
		}
	}
	&-menu {
		top: calc(100% - 1px);
		width: 100%;
		margin-top: 0;
		border-radius: 0;
	}
	&-item {
		&--notActive {
			color: $gray;
		}
	}
}