.calloutPanel {
	&-heading {
		margin-bottom: 3rem;
		font-size: 32px;
		line-height: 36px;
		text-align: center;
	}
	&-copy {
		font-family: 'Montserrat','Helvetica', sans-serif;
		font-size: 16px;
		line-height: 27px;
	}
	&-cta {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 2.5rem;
		padding: 3rem 2rem;
		background-color: $white;
		border: 1px solid palette(gray,200);
		text-decoration: none;
		&:after {
			content: '\f105';
			position: absolute;
			top: calc(100% - 22.5px);
			left: calc(50% - 22.5px);
			display: flex;
			align-items: center;
			justify-content: center;
			width: 45px;
			height: 45px;
			background-color: $softBlue;
			border-radius: 50%;
			color: $white;
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
		}
	}
	&-ctaPretitle {
		font-size: 10px;
		font-weight: bold;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
	}
	&-ctaTitle {
		color: $navyBlue;
		font-size: 20px;
		font-weight: bold;
		text-align: center;
		text-decoration: none;
	}
}