// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
/*
* We usually like to keep away from nesting/over specificity, but since we are including input types here
* as well as classes we need to so all styles are available to those input types as well
*/

.btn,
button,
// input[type='submit'],
input[type='button'],
input[type='reset'] {
    // @include font-size($fontSizeBase)
    @include transition($btn-transition);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: $buttonWeightBase;

    width: auto; // For button type inputs. Maybe don't mess with this ;)
    margin-bottom: 0; // For button type inputs. Maybe don't mess with this ;)
    padding: $spacer $spacer*0.5;

    text-transform: uppercase;
    text-shadow: 1px 1px palette(charcoal, 500);
    font-weight: 700;
    letter-spacing: 2px;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;

    border: 4px solid transparent;
    border-bottom-color: palette(flame, 200);
    border-radius: 0;
    background: $flame;
    box-shadow: 0px 0px 7px palette(charcoal, 0);
    color: $white;

    -webkit-appearance: none; //Remove rounded corners on iPhone
    touch-action: manipulation; // Remove 300ms touch delay
    position: relative;
    z-index: 1;
    overflow: hidden;
    font-size: 1rem;
    white-space: normal;
    &:hover {
        background: palette(flame, 600);
        border-bottom-color: palette(flame, 600);
        text-decoration: none;
        color: $white;
        box-shadow: none;
    }

    &:active {
        border-color: palette(flame, 300);
        background: palette(flame, 600);
        box-shadow: none;
    }

    &:focus {
        border-color: palette(flame, 300);
        background: palette(flame, 600);
        box-shadow: 0px 0px 7px palette(charcoal, 200);
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        cursor: not-allowed;
        pointer-events: none; // Future-proof disabling of clicks
        opacity: .65;
    }

    input[type='submit'],
    input[type='button'],
    input[type='reset'] {
        border: 1px solid transparent; // For button type inputs. Maybe don't mess with this ;)
    }

    /* VARIANTS
  ========================================================================== */
    &.btn--secondary {
        color: inherit;
        border: 4px solid palette(white, 500);
        background: palette(navyBlue, 500);
        box-shadow: 0px 4px rgb(131, 131, 149);
        background: $navyBlue;

        &:hover {
            background: palette(navyBlue, 800);
            text-decoration: none;
            color: $white;
            box-shadow: none;
        }

        &:active {
            background-color: palette(navyBlue, 800);
            box-shadow: none;
        }

        &:focus {
            background-color: palette(navyBlue, 800);
            box-shadow: 0px 0px 10px palette(softBlue, 300);
        }
    }

    &.btn--tertiary {
        color: inherit;
        border: 4px solid palette(navyBlue, 500);
        background: palette($white, 500);
        box-shadow: 0px 4px rgba(black, .08);
        text-shadow: 1px 1px rgba(black, .08);
        background: $white;
        transition: color .1s;

        &:hover {
            background: palette(navyBlue, 800);
            border-color: palette(navyBlue, 800); 
            text-decoration: none;
            color: $white;
            box-shadow: none;
            transition: color .5s;
        }

        &:active {
            background-color: palette(softBlue, 50);
            box-shadow: none;
        }

        &:focus {
            background-color: palette(gray, 50);
            box-shadow: 0px 0px 10px palette(softBlue, 50);
        }
    }
    &.btn--white-outline {
        background: none;
        border: 2px solid $white;
        color: $white;
        box-shadow: none;
        &:hover {
            color: $flame;
            border-color: $flame;
        }
    }

    &.btn--white {
        color: $navyBlue;
        border: 4px solid palette(navyBlue, 500);
        box-shadow: none;
        background: $white;
        text-shadow: none;

        &:hover {
            background: palette(charcoal, 50);
            border: 4px solid palette(navyBlue, 500);
            text-decoration: none;
            box-shadow: none;
        }

        &:active {
            border: 4px solid palette(navyBlue, 400);
            background: palette(charcoal, 50);
            box-shadow: none;
        }

        &:focus {
            border: 4px solid palette(navyBlue, 400);
            background: palette(charcoal, 50);
            box-shadow: 0px 0px 10px palette(softBlue, 300);
        }
    }

    &.btn--disabled {
        background-color: palette(charcoal, 200);
        border-bottom: none;
    }

    /*
   * These buttons will fill the width of their container.
   */
    &.btn--full {
        display: flex;
        width: 100%;
    }

    .btn--full+.btn--full {
        margin-top: $spacer/4;
    }

    /*
    * This button inherits font and other aspects of it's parent naturally
    */
    &.btn--natural {
        padding-right: .5em;
        padding-left: .5em;

        vertical-align: baseline;
        font-size: inherit;
        line-height: inherit;
    }


    /* SIZES
  ========================================================================== */
    /*
   * Button size modifiers.
   */
    &.btn--xsm {
        font-size: 12px;
        padding: $spacer*.25 $spacer*.75; // padding: 0 $spacingXSm;
    }

    &.btn--sm {
        padding: $spacer*.75 $spacer;
        font-size: 14px;
    }
}


.btn--arrow {
    &:after {
        content: '\f054';
        font-family: 'Font Awesome 5 Free';
        margin-left: $spacer*0.5;
    }
}


.btn--form {
    width: 100%;
    background-color: $flame;
    border: 0;
    border-radius: 5px;
    color: $white;
    font-size: 0.75rem;
    font-weight: 700;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right top, rgba($white, 0.0) 50%, rgba($white, 0.1) 50%);
    }
    &:after {
        content: '\f054';
        font-family: 'Font Awesome 5 Free';
        margin-left: $spacer*0.5;
    }
}