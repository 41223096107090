.accordion {
    display: flex;
    flex-direction: column;
    margin-bottom: $marginBase * 2;
    border: 2px solid palette(gray, 50);
    border-top: 5px solid $softBlue;
}

.accordion-item {
    display: flex;
    flex-direction: column;
    &:last-child {
        .accordion-trigger {
            margin-bottom: 0;
        }
    }
}

.accordion-trigger {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1px;
    padding: 0.5rem 1.5rem 0.5rem 0.5rem;
    background-color: $navyBlue;
    color: $white;
    font-size: 18px;
    transition: all .2s ease-in-out;
    cursor: pointer;

    &:after {
        position: absolute;
        top: 50%;
        right: $spacer;
        content: '';
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 5px solid $softBlue;
        transform: translateY(-50%) rotate(0deg);
        transition: 0.25s;
    }

    &:hover {
        background-color: palette(navyBlue,700);
    }
    &:focus {
        background-color: palette(navyBlue,700);
    }
    .is-open & {
        background-color: $white;
        color: $navyBlue;
        &:after {
            transform: translateY(-50%) rotate(-90deg);
        }
        a{
            color: #000;

        }
    }
    a{
        text-decoration: none;
        color:#fff;
    }
}

.accordion-content {
    display: none;
    padding: $spacer*2 $spacer;

    >*:last-child {
        margin-bottom: 0;
    }

    //just to display the content when the script adds the .is-open class on load and user click
    .is-open & {
        display: block;
    }

    //a nifty hook to do animations or whatevs when content is triggered open by user
    .is-opening & {
        animation: 1s accordion;
    }

    > p {
        font-size: 18px;
    }
}

@keyframes accordion {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


.accordion--style1 {
    border: none;
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
        //max-height: 344px;
        //overflow: auto;
    }
    @include media-breakpoint-up(md) {
        max-height: 424px;
    }
    .accordion-trigger {
        background-color: white;
        color: $flame;
        font-weight: bold;
    }
    .accordion-content {
        padding: 0.5rem;
        max-height: 344px;
        overflow-y: scroll;
    }
    .accordion-trigger:after {
        content: "\f078";
        font-family: "Font Awesome 5 Free";
        color: black;
        border: none;
        width: 1rem;
        height: 1rem;
    }
    .is-open .accordion-trigger:after {
        transform: translateY(-50%) rotate(-180deg);
        color: $flame;
    }
}