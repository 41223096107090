.spotlightIntro {
    padding-top: $spacer*2;
    padding-bottom: $spacer*2;
    &-title {
        font-size: 26px;
        font-weight: 300;
        text-align: center;
        @media (min-width: 992px) {
            margin-bottom: $spacer*3;
        }
    }
    &-copy {
        margin-bottom: $spacer*2.5;
        font-size: 1rem;
        line-height: 1.75rem;
    }
    &-listCopy {
        font-size: 0.875rem;
    }
    &-list {
        position: relative;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
    }
    &-listItem {
        position: relative;
        margin-bottom: $spacer*0.75;
        padding-left: $spacer*1.75;
        font-size: 0.875rem;
        &:before {
            content: '\f00c';
            position: absolute;
            top: -2px;
            left: 0;
            color: $flame;
            font-family: "Font Awesome 5 Free";
            font-size: 1rem;
            font-weight: 900;
        }
    }
}