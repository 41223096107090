
.multiColumnList {
	list-style: none;
	padding: 0;
	margin: 0;
	column-count: 2;
	column-gap: 20px;
	& > * {
		padding-left: 1rem;
	}
	&--dark {
		a {
			color: $navyBlue;
            text-decoration: none;
			&:hover {
				color: $softBlue;
			}
		}
	}
	@media (min-width: 700px) {
		column-count: 3;
	}
	@media (min-width: 850px) {
		column-count: 4;
	}
	@media (min-width: 1024px) {
		column-count: 5;
	}
}
