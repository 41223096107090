// -----------------------------------------------------------------------------
// This file contains all styles related to the link component.
// -----------------------------------------------------------------------------

.link-text {
    align-self: flex-start;
    padding: 0 0 $spacer*.25 0;
    color: inherit;
    letter-spacing: 2px;
    text-decoration: none;
    font-size: 1rem;
    margin-bottom: $spacer;
    background-color: transparent;
    position: relative;
    display: flex;

    &:after {
        content: '';
        height: $spacer/4;
        background-color: $softBlue;
        left: 0;
        position: absolute;
        top: 30px;
        width: 100%;
    }

    &:hover {
        text-decoration: none;

        &:after {
            transform: translateY($spacer/2);
            transition: .5s ease-in-out;
        }
    }
}

.link-textArrow {
    position: relative;
    display: inline-flex;
    order: 1;
    border-bottom: 3px solid $softBlue;
    padding: 0 0 $spacer*.25 0;
    
    .link-textCopy {
        color: $white;
        text-decoration: none;
        &:before {
            @extend %iconStyleFas;
            @extend .fa-arrow-right;
            color: $softBlue;
            order: 2;
            position: absolute;
            right: -30px;
            top: 5px;
        }

        &:hover {
            text-decoration: none;

            &:before {
                transform: translateX($spacer/2);
                transition: .5s ease-in-out;
            }
        }
    }
}
