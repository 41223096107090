// -----------------------------------------------------------------------------
// This file contains all styles related to the background component.
// -----------------------------------------------------------------------------

.mapFindProviders {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: $spacer*2;

    .mapFindProviders--iframe {
        flex-direction: row;
    }

    h1,
    h2,
    h3,
    h4 {
				color: $white;
				.fillSoftBlue & {
					color: $navyBlue;
				}
    }

    @include media-breakpoint-up(lg) {
        flex-wrap: wrap;
        height: 450px;
        padding: $spacer*2 0;

        &--small {
            height: 330px;

            div.mapFindProviders-map {
                width: 30% !important;
            }

            div.mapFindProviders-content {
                // height: 40%;
                // max-height: 40%;
            }

            div.mapFindProviders-content,
            div.mapFindProviders-form {
								width: 70% !important;
						}

						h3 {
							margin-bottom: 1rem;
						}
        }
    }
}

.mapFindProviders>div {
    width: 100%;
    display: flex;

    @include media-breakpoint-up(lg) {
        display: block;
        flex: none;
    }
}

div.mapFindProviders-map {
    background-image: url(/img/content-images/map-usa-orange.svg);
    background-size: auto;
    background-repeat: no-repeat;
    background-position: top center;
    order: 2;
    height: 200px;
    display: none;

    @include media-breakpoint-up(lg) {
        height: 260px;
    }

    @include media-breakpoint-up(lg) {
        display: block;
        flex-basis: 100%;
        width: 50%;
        order: 1;
        height: auto;
    }
}

div.mapFindProviders-content {
    order: 1;
    display: flex;
    flex-direction: column;
    margin-right: 5%;

    h2 {
        font-size: 16px;
    }

    @include media-breakpoint-up(sm) {
        h2 {
            font-size: $h2Size/1.5;
        }
    }

    @include media-breakpoint-up(lg) {
        left: 50%;
        margin-bottom: 30px;
        align-content: center;
        text-align: left;
        width: 45%;
        order: 1;

        h2 {
            font-size: 32px;
            margin-bottom: 0;
        }
    }
}

div.mapFindProviders-form {
    order: 3;
    margin-right: 5%;
    // margin-top: auto;
    // margin-bottom: 1rem;

    @include media-breakpoint-up(lg) {
        width: 45%;
    }

    .form-contents {
        width: 100%;
        margin-top: auto;

        input[type="text"] {
            @include placeholder {
                color: $white;
            }
        }

        .submit {
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: auto;
            }
				}
				.submit2 {
					display: block;
					margin-left: auto;
					margin-right: auto;
					@include media-breakpoint-up(lg) {
						margin-left: auto;
						margin-right: 0;
					}
				}
				.disclaimer {
					margin-top: $spacer*1.25;
					@include media-breakpoint-up(lg) {
						margin-top: $spacer*0.5;
					}
				}
    }
}


//modifiers
.mapFindProviders--smaller {
    @include media-breakpoint-up(lg) {
        height: 385px;
    }
    .mapFindProviders-content {
        h2 {
            font-size: 1.5rem
        }   
        h6 {
            font-size: 1rem
        }
    }
}