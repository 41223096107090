.holiday-card {
    position: relative;
    overflow: hidden;
    background-position: center center;
    background-size: cover;
    height: 100px;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    border-radius: 5px;
    text-decoration: none;
    transition: all 1s ease;
    
    @include media-breakpoint-up(md) {
        height: 140px;
    }
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: $navyBlue;
        opacity: 0.5;
    }
    &:hover {
        color: $white;
        &:before {
            opacity: 0.75;
        }
    }
}

.holiday-card__title {
    position: relative;
    z-index: 2;
    margin-bottom: 0;
    color: $white;
    font-size: 1rem;
    text-align: center;
    text-decoration: none;
    &:hover {
        color: $white;
    }
    @include media-breakpoint-up(md) {
        font-size: 1.875rem;
    }

}