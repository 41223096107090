// -----------------------------------------------------------------------------
// This file contains all styles related to the interstitial component.
// -----------------------------------------------------------------------------

.interstitialMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
}

.interstitialMain-inner {
    padding: 0;
    position: relative;

    @include media-breakpoint-up(lg) {
        padding: 0 ($spacer * 3);
        min-height: 500px;
    }
}

.interstitialMain-graphic {
    height: auto;
    padding-bottom: $spacer;

    img {
        width: auto;
        height: 100%;
        max-height: 300px;
    }
}

.interstitialMain-heading {
    width: 100%;
    text-align: center;
}

.interstitialMain-footer {
    text-align: center;
    margin: auto;
    padding: 0 $spacer;

    @include media-breakpoint-up(lg) {
        position: absolute;
        bottom: 0;
        right: 0;
        text-align: right;
    }
}

.interstitialMain-footerCopy {
    font-size: .85rem;
}

.interstital-logo {
    margin: 0 $spacer/3;
    width: 100%;
    max-width: 100px;

    @include media-breakpoint-up(md) {
        max-width: 200px;
    }
}

.interstitialMain-contentCopy {
    padding: $spacer;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
        padding: $spacer * 2;
    }
}

.interstitialAlt {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    &-inner {
        margin: 0 30px;
        padding: 20px 50px;
        background: $white;
        border: 6px solid #ddd;
        width: 100%;
        max-width: 1024px;
    }
}

.interstitialAlt-logo {
    img {
        max-width: 300px;
    }
}

.interstitialAlt-title {
    font-size: 24px;
    margin: 15px 0 25px;
    font-weight: 900;
}

.interstitialAlt-sponsors {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    &Title {
        color: $flame;
        text-transform: uppercase;
        flex: 0 0 100%;
        margin: 0 0 20px;
        text-align: center;
        font-weight: 900;
        font-size: 22px;
    }

    &Img {
        margin: 0 10px 15px;
    }
}

.interstitialAlt-meta {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0 10px;

    a {
        color: inherit;
        text-decoration: underline;
    }
}

.interstitialAlt-form {
    margin: 60px 0;

    .interstitialAlt-btn {
        background: transparent;
        padding: 5px 0 5px;
        text-shadow: none;
        color: $charcoal;
        border: none;
        text-transform: none;
        box-shadow: none;
        overflow: visible;
        border-bottom: 4px solid $softBlue;
        position: relative;
        outline: none;

        &:hover {
            i {
                left: calc(100% + 20px);
            }
        }

        i {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto 0;
            left: calc(100% + 10px);
            display: block;
            height: 10px;
            transition: .25s ease-out;
        }
    }
}
