ul.tabs{
  display: inline-block;
  width:100%;
  padding-left:0;
}

ul.tabs li {
  list-style:none;
  cursor:pointer;
  margin-right:20px;
  margin-left:20px;
  display: inline-block;
  border-bottom: 2px solid transparent;
  padding: 0 0px;
  text-transform: uppercase;
  letter-spacing: 0.44px;
  font-size: 14px;
  font-weight: bold;
}

ul.tabs li a { 
  text-decoration: none;
  color: #000;

}

ul.tabs li.active{
    border-bottom: 2px solid #59bfe6;
}

.tabs_container{
  padding-top:20px;
  display: inline-block;
  width:100%;
}

.tabs_container .tab_content{
  display: none;
}

.tabs_container .tab_content.active{
  display: block;
}