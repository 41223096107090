.logoLink {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 40px;
	padding: 10px;
}

.logoLink-img {
	overflow: hidden;
	border-radius: 50%;
	max-width: 150px;
}

.logoLink-copy {
	text-align: center;
	margin: 10px 0 15px;
}