h1,
h2,
h3,
h4,
h5,
h6,
hgroup,
ul,
ol,
dl,
blockquote,
p,
address,
fieldset,
figure,
pre,
{
    margin-top: 0;
    margin-bottom: $marginBase;
}



/**
 * Basic typography style for copy text
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: $headingsFontFamily;
    font-weight: $headingsFontWeight;
    line-height:1.25;
}

h1, h2, h3, h4 {
    color: $navyBlue;
}

h1,
.h1 {
    font-size:$h1Size;
}

h2,
.h2 {
    font-size:$h2Size;
}

h3,
.h3 {
    font-size:$h3Size;
    font-weight: 600;
}

h4,
.h4 {
    font-size:$h4Size;
    color: $flame;
    text-transform: uppercase;
    font-weight: 600;
}

h5,
.h5 {
    font-size:$h5Size;
}

h6,
.h6 {
    font-size:$h6Size;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: $spacer/1.25;
}

hr {
    margin-left: 0;
    margin-right: 0;
    border-width: 0;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-top: 1px solid;
        opacity: .25;
    }
}

a, a * {
    color: $linkColorBase;
    text-decoration: underline;
    background-color: transparent; // Remove the gray background on active links in IE 10.
    -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.
    @include hover {
        color: $linkColorHoverBase;
        text-decoration: none;
        transition: .4s ease-in-out;
    }

}
.bareList {
    list-style:none !important;
    padding:0 !important;
}

.tipsResources-title {
	padding-bottom: $spacer*1.25;
	border-bottom: 1px solid palette(gray,100);
	&--small {
		margin-top: $spacer;
		font-size: 1.125rem;
	}
}