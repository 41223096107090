.promo {
    background-image: url('/img/template-images/promo-bg.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    &-preTitle {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
    &-title {
        font-family: $headingsFontFamily;
        font-size: 28px;
        font-weight: 300;
        line-height: 34px;
        letter-spacing: -1px;
    }
    .btn {
        box-shadow: none;
    }
}

.promo--style1 {
    background-image: url('/img/content-images/speed-matters-bg-v1.jpg');
    text-align: center;
    .promo-preTitle {
        color: $softBlue;
        font-weight: bold;
        margin-bottom: 1.5rem;
        font-size: 1.375rem;
        text-transform: none;
        margin-top: 1rem;
        @include media-breakpoint-up(md) {
            font-size: 1.875rem;
        }
        @include media-breakpoint-up(lg) {
            font-size: 2rem;
        }
    }
    .promo-title {
        font-weight: 500;
        font-size: 1.25rem;
        margin-bottom: 1.5rem;
        @include media-breakpoint-up(md) {
            font-size: 1.5rem;
        }
        @include media-breakpoint-up(lg) {
            font-size: 1.75rem;
        }

    }
    .promo-copy {
        //margin-bottom: 2rem;
        font-size: 1rem;
    }
    .promo-btn {
        margin-bottom: 1rem;
    }
    
}