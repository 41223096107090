// -----------------------------------------------------------------------------
// This file contains all styles related to the carousel component.
// -----------------------------------------------------------------------------

.carousel {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 2000px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 600px;
}

.carousel-inner {
    position: relative;
    flex: 1 1 100%;

    .carousel-item {
        height: 100%;
        background-size:cover;
        img {
            &.is-centered {
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);

                &--horizontal {
                    left: 50%;
                    position: absolute;
                    transform: translateX(-50%);
                }

                &--vertical {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        &.is-centered {
            background-position: center;

            &--horizontal {
                background-position:top;
            }

            &--vertical {
                background-position:left;
            }
        }
    }
}

.carousel-form {
    width: 100%;
    padding: $spacer;
    position: relative;
    margin-top: -20%;

    .carousel-formHeading {
        font-size: $h1Size/2;
        margin-bottom: $spacer;
    }

    .background-split {
        @include media-breakpoint-up(lg) {
            align-content: flex-end;
            max-width: 500px;
            float: right;
        }
    }

    @include media-breakpoint-up(lg) {
        top: 0;
        position: absolute;
        margin-top: 0;
        height: auto;
        min-height: 400px;
        // display: flex;
        // justify-content: flex-end;
        padding: rem(45px);
        z-index: 2;

        .carousel-formHeading {
            font-size: $h1Size;
        }
    }

}


.carousel-indicators {
    justify-content: center;
    width: 100%;
    top: 110%;
    bottom: 0;
    @include media-breakpoint-up(sm) {
        top: auto;
        bottom: 15%;
        display: inline-flex;
        justify-content: flex-start;
        width: 110px;
        margin-left: 2.5rem;
        margin-right: 0;
    }

    @include media-breakpoint-up(xl) {
        bottom: 5%;
    }
}

.carousel-indicators li {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: 2px solid $white;
    background-color: palette(charcoal, 300);
    box-shadow: 0px 0px 3px rgba(palette(charcoal, 500), 0.8);

    @include media-breakpoint-up(sm) {
        display: inline-flex;
        max-width: 500px;
        align-content: flex-end;
    }
}

.carousel-indicators .active {
    background-color: $softBlue;
    border: 1px solid $softBlue;
    opacity: 1;
}

.carousel-formInner {
    @include media-breakpoint-up(lg) {
        align-content: flex-end;
    }
}
