.iconList {
    list-style: none;
    margin: 0;
    padding: 0;

    &-item {
        @extend %noPhatBottom;
        display: flex;
        align-items: center;
        margin-bottom: $spacer*2;

        &:before {
            display: inline-flex;
            flex: 0 0 auto;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            margin-right: $spacer;
            background-color: $navyBlue;
            border-radius: 50%;
            color: $softBlue;
            font-size: 28px;
            font-weight: bold;
        }

        &--truck {
            &:before {
                content: '\f0d1';
                font-family: 'Font Awesome 5 Free';
                font-size: 18px;
            }
        }

        >span {
            font-family: 'Montserrat', Helvetica, Arial, sans-serif;
            font-size: 18px;
            font-weight: 600;
        }
    }
}

.iconList-item.iconList-item--truck {
    flex-direction: column;

    &:before {
        margin-bottom: $spacer;
    }

    @include media-breakpoint-up(sm) {
        flex-direction: row;

        &:before {
            margin-bottom: 0;
        }
    }
}
