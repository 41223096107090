.section {
    @extend %noPhatBottom;
    max-width:100%;
    // overflow-x:hidden;
    padding-top: $spacer*2;
    padding-bottom: $spacer*2;
    @include media-breakpoint-up(lg) {
        padding-top: $spacer*2;
        padding-bottom: $spacer*2;
    }
    &-intro {
        // margin-bottom: $spacer*3;
		}
		&--hideX {
			position: relative;
			overflow-x: hidden;
		}
		&--hideY {
			position: relative;
			overflow-y: hidden;
		}
		&--overflowHidden {
			position: relative;
			overflow: hidden;
		}
}

.section-nopad {
    &--top {
        padding-top: 0;
    }
}

.section--bordered {
    border-bottom:4px solid palette(charcoal, 50);
}

.section--capped{
    border-top: 1px solid palette(charcoal,50);
}
