.checklist {
	counter-reset: checklist;
	margin: 20px 0;
}

.checklist-head {
	font-size: 26px;
	color: #1d1d3f;
	counter-increment: checklist;
	margin: 30px 0 10px;
	&:before {
		content: counter(checklist) '.';
		margin-right: 10px;
	}
}

.checklist-list {
	list-style: none;
	margin: 0;
	padding: 0;
	
}

.checklist-item {
	position: relative;
	margin-bottom: 15px;
	input {
		height: 0;
		width: 0;
		position: absolute;
		top: 0;
		left: 0;
		border: none;
		background: none;
		outline: none;
		appearance: none;
		opacity: 0;
		visibility: hidden;
		&:checked + label {
			&:before {
				border-color: palette(softBlue, 600);
			}
			&:after {
				transform: rotate(405deg) scale(1, 1);
			}
		}
	}
}

label.checklist-itemTitle {
	font-weight: 900;
	font-size: 1.2rem;
	position: relative;
	margin: 0;
	padding-left: 40px;
	cursor: pointer;
	&:before {
		height: 25px;
		width: 25px;
		content: '';
		border: 2px solid #ccc;
		position: absolute;
		left: 0;
		top: 2px;
		transition: .25s ease-out;
	}
	&:after {
		height: 18px;
		width: 9px;
		border-bottom: 3px solid palette(softBlue, 600);
		border-right: 3px solid palette(softBlue, 600);
		transform: rotate(45deg) scale(0,0);
		transition: .25s ease-out;
		position: absolute;
		left: 8px;
		top: 3px;
		display: block;
		content:'';
	}
}

.checklist-itemInfo {
	margin: 0;
	margin-left: 40px;
}