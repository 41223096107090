// -----------------------------------------------------------------------------
// This file contains all styles related to the featured loading component.
// -----------------------------------------------------------------------------

// .loading-rotate{
//     width:100px;
//     height:100px;
//     margin:50px auto;
//     position:relative;
//   }
//   .loading-rotate>span{
//     width:15px;
//     height:15px;
//     border-radius:50%;
//     position:absolute;
//     animation:loading .9s infinite ease-in-out;
//     background:$softBlue;
//   }
//   .loading-rotate span:first-of-type{
//     right:28px;
//     animation-delay:.95s;
//   }
//   .loading-rotate span:nth-of-type(2){
//     top:6px;
//     right:51px;
//     animation-delay:.85s;
//   }
//   .loading-rotate span:nth-of-type(3){
//     right:62px;
//     top:24px;
//    animation-delay:.75s;
//   }
//   .loading-rotate span:nth-of-type(4){
//     right:60px;
//     top:45px;
//     animation-delay:.65s;
//   }
//   .loading-rotate span:nth-of-type(5){
//     right:45px;
//     top:60px;
//     animation-delay:.55s;
//   }
//   .loading-rotate span:nth-of-type(6){
//     right:24px;
//     top:61px;
//     animation-delay:.45s;
//   }
//   .loading-rotate span:nth-of-type(7){
//     right:5px;
//     top:50px;
//     animation-delay:.35s;
//   }
//   .loading-rotate span:nth-of-type(8){
//     right:0;
//     top:30px;
//     animation-delay:.25s;
//   }
//   .loading-rotate span:last-of-type{
//     right:7px;
//     top:10px;
//     animation-delay:.15s;
//   }
//   @keyframes loading{
//     from{
//       opacity:1
//     }
//     to{
//       opacity:0
//     }
//   }


#loading-container {
  z-index: 1;
  margin: 0px auto;
  position: relative;
}

.loading {
  display: inline-block;
  height: 100%;
  margin: 50px;
  max-width: 170px;
  max-height: 170px;
  width: 100%;
}

.loading-circles {
  position: relative;
  
  .spoke {
    position: absolute;
    border-radius: 100%;
    animation-name: loading-circles;
    animation-iteration-count: infinite;
  }
  
  @keyframes loading-circles {
    from {
      background-color: palette(softBlue, 500);
      padding: 8px;
    }
    
    to {
      background-color: palette(softBlue, 100);
      padding: 0px;
    }
  }
}

.loading-rectangles {
  position: relative;
  
  .spoke {
    position: absolute;
    animation-name: loading-rectangles;
    animation-iteration-count: infinite;
  }
  
  @keyframes loading-rectangles {
    from {
      background-color: red;
    }
    
    to {
      background: blue;
    }
  }
}
