// -----------------------------------------------------------------------------
// This file contains all styles related to the background component.
// -----------------------------------------------------------------------------

.bg {
    &SoftWhite {
        background-color: $softWhite;
    }

    &Flame {
        background-color: $flame;
    }

    &SoftBlue {
        background-color: $softBlue;
    }

    &Charcoal {
        background-color: $charcoal;
    }

    &NavyBlue {
        background-color: $navyBlue;
    }

    &logoFill-navyBlue {
        background: $navyBlue url(/img/content-images/pattern-dark-bg.svg) no-repeat;

        &--mobileUnset {
            @include media-breakpoint-down(sm) {
                background: $navyBlue;
            }
        }
    }
}

.logoBgLeft {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-image: url(/img/content-images/pattern-content-block.svg);
    background-position: bottom left;
}

.logoBgCornerLeft {
    background-image: url(/img/content-images/pattern-footer.svg);
    background-position: bottom left;
    background-repeat: no-repeat;
    background-position-x: -4%;
    background-position-y: 114%;
}

.logoBgRight {
    @include media-breakpoint-up(lg) {
        background-image: url(/img/content-images/pattern-dark-bg.svg);
        background-position: top right;
        background-repeat: no-repeat;
    }
}

.background-split {
    border: 7px solid $flame;
    padding: $spacer*2;
    align-content: center;
    background-color: $white;
    @include linear-gradient(to right top, $white 50%, palette(charcoal, 50) 50%);

    &--narrow {
        @extend .background-split;
    }

    &--gray {
        border-color: rgb(209, 209, 209);
		}
		&.speedTest {
			height: auto;
		}
}


.fillNavyBlueGradient {
    background: linear-gradient(180deg, rgba(29, 29, 63, 1) 0%, rgba(29, 29, 63, 0.95) 100%);
}