.headerUtil {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 3rem 0;
	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 0;
		width: 50%;
		height: 100%;
		background-image: url('/img/content-images/pattern-footer.svg');
		background-repeat: no-repeat;
		background-size: 50%;
		opacity: 0.6;
		@media (min-width: 992px) {
			background-size: 30%;
		}
	}
	&:before {
		left: 0;
		background-position: bottom left;
		transform: translateX(-10%);
	}
	&:after {
		right: 0;
		background-position: bottom right;
		transform: translateX(10%);
	}
	&-inner {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		text-align: center;
		&:before,
		&:after {
			content: '';
			// position: absolute;
			// top: 50%;
			display: inline-block;
			flex: 1 1 auto;
			// width: 20px;
			height: 10px;
			background-color: $navyBlue;
			transform: translateY(-50%);
			@media (min-width: 768px) {
				width: 25%;
			}
			@media (min-width: 1200px) {
				width: 30%;
			}
		}
		&:before {
			margin-right: 2rem;
			border-radius: 0 99px 99px 0;
		}
		&:after {
			margin-left: 2rem;
			border-radius: 99px 0 0 99px;
		}
	}
	&-copy {
		flex: 1 1 auto;
		@media (min-width: 768px) {
			flex: 1 0 auto;
		}
	}
	&-title {
		margin-bottom: 0.5rem;
		font-size: 24px;
		@media (min-width: 768px) {
			font-size: 28px;
		}
		@media (min-width: 992px) {
			font-size: 36px;
		}
	}
	&-subtitle {
		margin-bottom: 0;
		color: $softBlue;
		font-size: 16px;
		text-transform: uppercase;
		@media (min-width: 768px) {
			font-size: 18px;
		}
		@media (min-width: 992px) {
			font-size: 20px;
		}
	}
}