.dataTable {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 100%;
    margin-bottom: $spacer * 2;
    border-collapse: collapse;
    overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
		

    thead {
        position: sticky;
        left: 0;
        flex: 0 1 25%;
        background-color: $navyBlue;
        box-shadow: 3px 0 10px rgba(black, 0.2);
        z-index: 1;

        @media (min-width: 768px) {
            position: static;
            left: auto;
            box-shadow: none;
        }

        th {
            display: flex;
            align-items: flex-start;
            background-color: $navyBlue;
            color: $white;

            @media (min-width: 768px) {
				display: table-cell;
				height: 80px !important;
                border-top: 1px solid #ccc;

                &:first-child {
                    border-radius: 10px 0 0 0;
                }

                &:last-child {
                    border-radius: 0 10px 0 0;
                }
            }
        }
    }

    tbody {
        display: flex;
        -webkit-overflow-scrolling: touch;
        flex: 0 0 75%;
        width: 1px;
    }

    tr {
        display: block;
        flex: 1 1 auto;
    }

    td {
        display: block;
        min-width: 150px;
        &:first-child {
            //text-align: center;
            vertical-align: middle;
        }
        &:nth-child(2) {
            max-width: 650px;
        }
        //&:nth-child(3) {
            //text-align: center;
        //}
        //&:last-child {
			//text-align: center;
        //}

        i {
					margin: 5px;
					font-size: 16px;
					@media (min-width: 768px) {
						font-size: 20px;
					}
					// @media (min-width: 1024px) {
					// 	font-size: 24px;
					// }
        }

        .specials {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @media (min-width: 768px) {
                flex-direction: row;
            }

            .title {
                margin-right: $spacer*0.5;
            }
        }

        svg {
            max-width: 100px;
            max-height: 50px;
            // margin: auto;
        }

        .link-text {
            display: inline-flex;
        }
    }

    td,
    th {
        padding: 8px;
        font-size: 14px;
        text-align: left;
        line-height: 1.25;
        background-color: #fff;

        @media (min-width: 768px) {
            font-size: 16px;
        }

        @media (min-width: 1100px) {
            padding: $spacer*1.75 !important;
        }

        >span {
            @media (min-width: 768px) {
                display: none;
            }
        }
    }

    th {
        display: flex;
        align-items: center;
        min-width: 80px;
        background-color: #f5f6f7;
        color: $black;
        font-size: 18px;
        font-weight: 700;

        @media print {
            color: $black;
            font-weight: 700;
        }
    }

    @media print,
    (min-width: 768px) {
        display: table;

        tbody {
            display: table-row-group;
        }

        tr {
            display: table-row;
            flex: 0;
        }

        th,
        td {
            display: table-cell;
            min-width: 160px;
            padding: $spacer*1;
            height: calc(1em + #{$spacer * 1.5});
        }

        td {
            font-size: 13px;

            &:last-child {
                min-width: 160px;
                //text-align: center;
            }
        }

        th {
            &.dataTable-shrinkCol {
                width: 10px;
                //text-align: center;
            }
        }
    }

    &--striped {
        tbody tr:nth-child(even) {
            background-color: gray;
        }
    }

    &--sortable {
        th {
            position: relative;
            cursor: pointer;
            padding-right: $spacer * 1.5;

            &.dataTable-shrinkCol {
                min-width: calc(90px + #{$spacer * 1.5});
            }

            &:before,
            &:after {
                content: "";
                width: 0;
                height: 0;
                position: absolute;
                right: $spacer * 0.75;
                top: 50%;
                display: block;
            }

            &:before {
                border-right: 2px solid transparent;
                border-left: 2px solid transparent;
                border-bottom: 3px solid $black;
                transform: translateY(-3px);
            }

            &:after {
                border-right: 2px solid transparent;
                border-left: 2px solid transparent;
                border-top: 3px solid $black;
                transform: translateY(3px);
            }
        }
    }
}

.dataTable.dataTable--2col {
	th, td {
		width: 50%;
		text-align: center;
		@include media-breakpoint-up(md) {
			font-size: 16px !important;
		}
	}
	td {
		&:first-child {
			font-weight: bold;
		}
	}
}

.dataTable.dataTable--3col {
	th, td {
		text-align: left;
		@include media-breakpoint-up(md) {
			font-size: 16px !important;
		}
		&:nth-child(1) {
			font-weight: bold;
			@include media-breakpoint-up(md) {
				width: 20%;
			}
		}
		&:nth-child(2),
		&:nth-child(3) {
			@include media-breakpoint-up(md) {
				width: 40%;
			}
		}
	}
}


// Modifier .dataTable--big
.dataTable--big {
    th {
        font-size: 0.85rem;
        @media (min-width: 868px) {
            font-size: 1rem;
        }
    }
    th, td {
        min-width: 50px;
        padding: 0.5rem;
        @media (min-width: 1100px) {
            padding: $spacer*.5 !important;
        }
    }
    @media print,
    (min-width: 768px) {
        th, td {
            min-width: 50px;
            padding: 0.5rem;
        }
    }
}

.dataTable--stripedBlue {
    background-color: pink;
    tr:nth-child(even) {
        background: #f4fbfd;
        td {
            background: #f4fbfd
        }
    }
}

