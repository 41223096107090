.linkList {
	margin-bottom: 20px;
	padding: 30px;
	border: 1px solid #ccc;
	border-radius: 10px;
	&-title {
		margin-bottom: 16px;
		color: $navyBlue;
		font-size: 10px;
		font-weight: bold;
		text-transform: uppercase;
	}
	&-list {
		margin: 0;
		padding: 0;
		list-style: none;
	}
	&-item {
		margin-bottom: 5px;
	}
	&-link {
		display: flex;
		align-items: center;
		font-size: 16px;
		text-decoration: none;
		&:after {
			content: '';
			display: inline-block;
			width: 8px;
			height: 8px;
			margin-left: 5px;
			margin-top: -1px;
			border-right: 1px solid $softBlue;
			border-bottom: 1px solid $softBlue;
			transform: rotate(-45deg);
		}
	}
}