// .popup-content > [id^="popup_"] {
.smsMessageModal {
    width: 625px;
    position: relative;
    padding: $spacer*1.5 $spacer * 3.5;
    background-color: $white;

    &-alert {
        background-color: #eeeeee;
        display: flex;
        padding: $spacer/2 $spacer;
        align-items: center;
        margin-bottom: $spacer * 1.75;
    }
    &-alertIcon {
        line-height: 1.75;
        color: $black;
        margin-right: 8px;
    }
    &-alertCopy {
        font-size: 14px;
        margin-bottom: 0;
    }
    &-heading {
        font-size: 18px;
        font-weight: 400;
    }
    &-close {
        position: absolute;
        z-index: 10;
        top: 25px;
        right: 17px;
        height: 25px;
        width: 25px;
        color: $softBlue;
        cursor: pointer;

        &:before,
        &:after {
            position: absolute;
            content: "";
            display: block;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            height: 5px;
            width: 25px;
            background: #59bfe6;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            border-radius: 5px;
        }

        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
}

.smsMessageModal-confirmation {
    background-color: $white;

    &-body {
        display: flex;
        align-items: center;
        padding: $spacer * 3 0;
        max-width: 70%;
        margin: 0 auto;
        color: $navyBlue;
        text-align: center;
    }

    &-icon {
        font-size: 28px;
        margin-right: 5px;
        &--success {
            i {
                color: $softBlue;
            }
        }
        &--error {
            i {
                color: $flame;
            }
        }
    }

    &-copy {
        margin-bottom: 0;
        text-align: left;
        line-height: 1.3;
    }
}

.smsMessageOverlay {
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($black, .75);
    z-index: 10;

    &.is-active {
        display: flex;
    }
}
// }
