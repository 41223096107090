// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.headerMain {
    max-width: $maxWidth;
    margin: auto;

    @include media-breakpoint-up(lg) {
        height: auto;
    }
    .hidden {
        display: none;
    }
    &--landing {
        .headerMain-logoLink {
            padding-right: $spacer;
            img {
                width: auto;
                max-width: 175px;
                height: auto;
                @media (min-width: 400px) {
                    max-width: 200px;
                }
                @include media-breakpoint-up(xl) {
                    max-width: 290px;
                }
            }
        }
        .headerMain-nav {
            position: relative;
            flex: 0 0 auto;
            flex-direction: column;
            background: transparent;
						opacity: 1;
            z-index: 1;
            .headerMain-navMainUtility {
                display: none;
                font-size: 14px;
                @include media-breakpoint-up(lg) {
                    display: block;
                    text-align: right;
                }
            }
            .headerMain-navContact {
                .headerMain-navContactHeader {
                    display: none;
                    @include media-breakpoint-up(md) {
                        display: block;
                    }
                }
                .headerMain-navContactPhone {
                    font-size: 14px;
                    @include media-breakpoint-up(md) {
                        font-size: 16px;
                    }
                }
                .headerMain-navContactPhone {
                    &:before {
                        display: none;
                        margin-right: 0.25rem;
                        font-size: 14px;
                        vertical-align: unset;
                        @include media-breakpoint-up(md) {
                            display: inline-block;
                        }
                    }
                }
            }
        }
		}
		&--cordCutting {
			.headerMain-navMain {
				justify-content: flex-start;
				padding-top: 50px;
				overflow-y: scroll;
				@media (min-width: 992px) {
					padding-top: 0;
					overflow-y: visible;
				}
				ul.headerMain-navMainList {
					position: relative;
					top: auto;
					width: 100%;
					transform: translateY(0);
				}
			}
			.headerMain-logoLink {
				display: flex;
				align-items: center;
				text-decoration: none;
				img {
						max-width: 125px;
                        height: auto;
						@media (min-width: 768px) {
							max-width: 185px;
						}
				}
				a.text-link {
					display: inline-block;
					max-width: 90px;
					margin-left: 16px;
					padding-left: 16px;
					border-left: 1px solid #ccc;
					color: $navyBlue;
					font-size: 12px;
					font-weight: bold;
					text-decoration: none;
					text-transform: uppercase;
				}
			}
			.headerMain-navMainItem {
				position: relative;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				&:hover {
					@media (min-width: 992px) {
						.headerMain-navDropdown {
							display: block;
							pointer-events: all;
							opacity: 1;
							visibility: visible;
							transform: translateX(-50%) translateY(0px);
						}
					}
				}
				&.is-open {
					.headerMain-navDropdown {
						max-height: 1500px;
						margin-top: 10px;
						padding-top: 20px;
						border-top: 1px solid rgba(#ccc,0.4);
						border-bottom: 1px solid rgba(#ccc,0.4);
					}
				}
				.headerMain-navMainLink {
					display: inline-flex;
					align-items: center;
					height: 32px;
				}
			}
			.mobileTrigger {
				flex: 0 1 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 30px;
				margin-left: 10px;
				padding: 0;
				background-color: transparent;
				border: 0;
				box-shadow: none;
				text-shadow: none;
				order: 2;
				@media (min-width: 992px) {
					pointer-events: none;
					flex: 0 1 15px;
					width: 15px;
					height: 15px;
				}
				&:before {
					margin: 0;
				}
			}
			.headerMain-navDropdown {
				flex: 1 1 100%;
				max-height: 0;
				margin: 0;
				padding: 0;
				background-color: $white;
				order: 3;
				overflow: hidden;
				list-style: none;
				transition: 0.25s ease;
				@media (min-width: 992px) {
					position: absolute;
					top: calc(100% + 6px);
					left: 50%;
					display: block;
					width: 100%;
					min-width: 200px;
					max-height: 1500px;
					padding: 20px;
					background-color: $white;
					box-shadow: 0 10px 10px rgba($black,0.3);
					pointer-events: none;
					opacity: 0;
					visibility: hidden;
					transform: translateX(-50%) translateY(-20px);
					transition: 0.25s ease;
				}
			}
			.headerMain-navDropdownItem {
				@media (min-width: 992px) {
					padding: 5px;
				}
			}
			a.headerMain-navDropdownLink {
				color: $navyBlue !important;
				&:hover {
					color: $flame !important;
				}
			}
		}
        &--dropdowns {
			.headerMain-navMain {
				justify-content: flex-start;
				padding-top: 50px;
				overflow-y: scroll;
				@media (min-width: 992px) {
					padding-top: 0;
					overflow-y: visible;
				}
				ul.headerMain-navMainList {
					position: relative;
					top: auto;
					width: 100%;
					transform: translateY(0);
				}
			}
			.headerMain-logoLink {
				display: flex;
				align-items: center;
				text-decoration: none;
				// img {
				// 		max-width: 125px;
                //         height: auto;
				// 		@media (min-width: 768px) {
				// 			max-width: 185px;
				// 		}
				// }
				// a.text-link {
				// 	display: inline-block;
				// 	max-width: 90px;
				// 	margin-left: 16px;
				// 	padding-left: 16px;
				// 	border-left: 1px solid #ccc;
				// 	color: $navyBlue;
				// 	font-size: 12px;
				// 	font-weight: bold;
				// 	text-decoration: none;
				// 	text-transform: uppercase;
				// }
			}
			.headerMain-navMainItem {
				position: relative;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				&:hover {
					@media (min-width: 992px) {
						.headerMain-navDropdown {
							display: block;
							pointer-events: all;
							opacity: 1;
							visibility: visible;
							transform: translateX(-50%) translateY(0px);
						}
					}
				}
				&.is-open {
					.headerMain-navDropdown {
						max-height: 1500px;
						margin-top: 10px;
						padding-top: 20px;
						border-top: 1px solid rgba(#ccc,0.4);
						border-bottom: 1px solid rgba(#ccc,0.4);
					}
				}
				.headerMain-navMainLink {
					display: inline-flex;
					align-items: center;
					height: 32px;
				}
			}
			.mobileTrigger {
				flex: 0 1 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 30px;
				margin-left: 10px;
				padding: 0;
				background-color: transparent;
				border: 0;
				box-shadow: none;
				text-shadow: none;
				order: 2;
				@media (min-width: 992px) {
					pointer-events: none;
					flex: 0 1 15px;
					width: 15px;
					height: 15px;
				}
				&:before {
					margin: 0;
				}
			}
			.headerMain-navDropdown {
				flex: 1 1 100%;
				max-height: 0;
				margin: 0;
				padding: 0;
				background-color: $white;
				order: 3;
				overflow: hidden;
				list-style: none;
				transition: 0.25s ease;
				@media (min-width: 992px) {
					position: absolute;
					top: calc(100% + 6px);
					left: 50%;
					display: block;
					width: 100%;
					min-width: 200px;
					max-height: 1500px;
					padding: 20px;
					background-color: $white;
					box-shadow: 0 10px 10px rgba($black,0.3);
					pointer-events: none;
					opacity: 0;
					visibility: hidden;
					transform: translateX(-50%) translateY(-20px);
					transition: 0.25s ease;
				}
			}
			.headerMain-navDropdownItem {
				@media (min-width: 992px) {
					padding: 5px;
				}
			}
			a.headerMain-navDropdownLink {
				color: $navyBlue !important;
				&:hover {
					color: $flame !important;
				}
			}
		}
}

.headerMain-inner {
    width: 100%;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 75px;
    padding: $spacer;

    @include media-breakpoint-up(md) {
        padding: 0 $spacer;
    }

    @include media-breakpoint-up(lg) {
        align-items: flex-end;
        height: auto;
        padding: $spacer $spacer 0;
    }
}

.headerMain-logoLink {
    img {
        max-width: 200px;
        height: auto;
    }

    label {
        position: relative;
        color: $charcoal;
        font-size: 1rem;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: $spacer;

        img {
            max-width: 290px;
        }
    }
}

.headerMain-nav {
    display: flex;
}

.headerMain-navMain {
    position: fixed;
    top: 75px;
    left: 0;
    height: 100%;
    width: 100%;
    background: $navyBlue;
    opacity: 0;
		z-index: -1;
		
		@include media-breakpoint-down(lg) {
			height: calc(100% - 75px);
		}

    @include media-breakpoint-up(lg) {
        top: 0;
        z-index: 9990;
    }

    .headerMain-navMainUtility {
        position: relative;
        width: 100%;
        text-align: center;
        padding: $spacer;
        text-decoration: underline;
        border-bottom: 1px solid palette(navyBlue, 400);

        .headerMain-navMainLink {
            color: $white;
            padding-right: $spacer;

            &:hover {
                color: $softBlue;
            }
        }

        @include media-breakpoint-up(lg) {
            border: none;
            position: relative;
            text-align: right;
            padding: 0;
            font-size: .85rem;

            .headerMain-navMainLink {
                color: $charcoal;
            }
        }
    }

    .headerMain-navMainList {
        margin: 0;
        padding: 0;
    }

    @include media-breakpoint-up(lg) {
        position: relative;
        height: auto;
        width: auto;
        background: $white;
        opacity: 1;
    }

    .headerMain-navMainItem {
        font-family: $headingsFontFamily;
        font-size: 1.5rem;
        list-style-type: none;
        text-align: center;
        margin: auto;
        font-weight: 400;
        letter-spacing: 0.04em;
        border-bottom: 6px solid transparent;

        @include media-breakpoint-up(lg) {
            text-transform: uppercase;
            font-weight: 600;
            font-size: .75rem;
            display: inline-flex;
            align-items: center;
            padding: $spacer*2 0.5rem $spacer;

            &:not(:first-child) {
                margin-left: 5px;
            }

            &.active,
            &:hover {
                border-bottom: 6px solid $softBlue;
                text-decoration: none;
						}

						.headerMain--cordCutting & {
							padding-left: 0;
							padding-right: 0;
							@media (min-width: 1200px) {
								padding-left: 1rem;
								padding-right: 1rem;
							}
						}
        }

        @include media-breakpoint-up(xl) {
            padding: $spacer*2 $spacer $spacer;

            &:not(:first-child) {
                margin-left: 25px;
            }
        }

        a {
            opacity: 0;
            color: $white;
            text-decoration: none;
            transform: translateY(-20px);

            @include media-breakpoint-up(lg) {
                color: $charcoal;
                opacity: 1;
                transform: none;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .headerMain-navMain {
        ul.headerMain-navMainList {
            position: absolute;
            top: 40%;
            transform: translateY(-50%);
            width: 100%;

            li {
                padding-bottom: $spacer;
            }

            li:nth-child(1) a {
                transition-delay: 0.2s;
            }

            li:nth-child(2) a {
                transition-delay: 0.3s;
            }

            li:nth-child(3) a {
                transition-delay: 0.4s;
            }

            li:nth-child(4) a {
                transition-delay: 0.5s;
            }

            li:nth-child(5) a {
                transition-delay: 0.6s;
            }

            li:nth-child(6) a {
                transition-delay: 0.7s;
            }
        }
    }

    .headerMain-nav {
        display: flex;
        flex-direction: row;
    }

    .headerMain-open {
        cursor: pointer;
        z-index: 9999;
        border-radius: 50%;
        justify-content: flex-end;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        font-family: $headingsFontFamily;
        font-weight: 600;
        letter-spacing: .15em;
        font-size: .8rem;

        &:after {
            content: 'Menu';
            text-transform: uppercase;
            align-self: flex-end;
        }

        .indicators {
            display: block;
            padding-right: $spacer/2;
        }

        i {
            display: block;
            width: 15px;
            height: 2px;
            background: $softBlue;
            border-radius: 2px;
            margin-left: 14px;

            &:nth-child(1) {
                margin-top: 0px;
            }

            &:nth-child(2) {
                margin-top: 4px;
                opacity: 1;
            }

            &:nth-child(3) {
                margin-top: 4px;
            }
        }
    }
}

#headerMain:checked+.headerMain-open {
    i {
        transform: rotate(90deg);
        transition: transform 0.2s ease;

        &:nth-child(1) {
            transform: translateY(6px) rotate(45deg);
        }

        &:nth-child(2) {
            opacity: 0;
            transform: translateY(1px);
        }

        &:nth-child(3) {
            transform: translateY(-6px) rotate(-45deg);
        }
    }

    &:after {
        content: 'Close';
        text-transform: uppercase;
    }
}

#headerMain:checked~.headerMain-navMain {
    z-index: 9990;
    opacity: 1;

    ul li a {
        opacity: 1;
        transform: translateY(0);
    }
}

//-- Header with no navigation, only contact details

.headerMain-navContact {
    text-align: right;
    padding: $spacer 0;

    .headerMain-navContantHeader {
        margin: 0;
        font-weight: 300;
    }

    .headerMain-navContactPhone {
        font-family: $headingsFontFamily;
        font-size: 1rem;
        font-weight: 600;

        &:before {
            @extend %iconStyleFas;
            @extend .fa-phone;
            color: $softBlue;
            padding-right: 0;
            font-size: 1rem;
            vertical-align: middle;
        }

        a {
            color: $charcoal;
            text-decoration: none;
            &:hover {
                text-decoration: none;
                color: $softBlue;
            }
        }
    }
}

.headerMain-navMainItem--campaign {
	&.active a {
		color: $fontColorBase !important;
	}
	i {
		margin-right: $spacer/2;
		color: $softBlue;
		font-size: 150%;
		vertical-align: middle;
	}
	
}
