.slider {
	position: relative;
	margin-left: 35px;
	margin-right: 35px;
	@media (min-width: 825px) {
		margin-left: 70px;
		margin-right: 70px;
	}
	.slick-list {
		overflow: hidden;
	}
	.slick-arrow {
		position: absolute;
		top: 45%;
		transform: translateY(-50%);
		height: 40px;
		width: 40px;
		background: #fff;
		border-radius: 50%;
		border: none;
		font-size: 0;
		color: transparent;
		box-shadow: 3px 3px 10px rgba(0,0,0,0.15);
		cursor: pointer;
		@media(min-width: 825px) {
			height: 60px;
			width: 60px;
		}
		&.slick-prev {
			right: 100%;
			&:after {
				transform:  translate(-50%, -50%) rotate(45deg);
				margin-left: 3px;
				cursor: pointer;
				@media(min-width: 825px) {
					margin-top: 0;
				}
			}
		}
		&.slick-next {
			left: 100%;
			&:after {
				transform:  translate(-50%, -50%) rotate(-135deg);
				margin-left: -3px;
				cursor: pointer;
				@media(min-width: 825px) {
					margin-top: 0;
				}
			}
		}
		&:after {
			position: absolute;
			top: 50%;
			left: 50%;
			height: 15px;
			width: 15px;
			content: '';
			display: flex;
			border-collapse: collapse;
			border-left: 4px solid $flame;
			border-bottom: 4px solid $flame;
			-webkit-mask-image: -webkit-radial-gradient(white, black);
			@media (min-width: 825px) {
				height: 20px;
				width: 20px;
				content: '';
				border-left: 6px solid $flame;
				border-bottom: 6px solid $flame;
			}
		}
	}
}