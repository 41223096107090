.orderList-list {
    counter-reset: item;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    list-style: none;
    padding: 0;
}

.orderList-item {
    @extend %noPhatBottom;
    align-items: center;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: $spacer*2;

    &:before {
        align-items: center;
        background-color: $navyBlue;
        border-radius: 50%;
        color: $softBlue;
        counter-increment: item;
        content: counters(item, ".") " ";
        display: inline-flex;
        flex: 0 0 auto;
        font-family: 'Montserrat', Helvetica, Arial, sans-serif;
        font-size: 28px;
        font-weight: bold;
        justify-content: center;
        margin-right: $spacer;
        width: 50px;
        height: 50px;
    }
}