:root {
  --star-size: 30px;
  --star-color: #ccc;
  --star-background: #fc0;
}

.ratings {
  margin-bottom: 1.5rem;
}
.stars {
  --percent: calc(var(--rating) / 5 * 100%);
  display: inline-block;
  font-size: var(--star-size);
  font-family: Times; // make sure ★ appears correctly
  line-height: 1;
  
  &::before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.starTotal {
  display: inline-block;
  font-weight: bold;
  letter-spacing: 0.1rem;

}

.starReviews {
  display: inline-block;
}

.starQuote {
  padding: 0.5rem;
  border: 1px solid #ccc;
  p {
    margin-bottom: 0;
  }
}