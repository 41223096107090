// -----------------------------------------------------------------------------
// This file contains all styles related to the form component.
// -----------------------------------------------------------------------------

.form-content {
    display: block;
}

.required {
    &:after {
        content: 'required';
        position: relative;
        top: -14px;
        width: auto;
        margin: 0;
        padding: 0;
        font-size: .75rem;
        color: inherit;
        font-weight: 300;
        font-style: italic;
        font-family: $headingsFontFamily;
    }

    .has-error {
        border-bottom: 2px solid $flame;
    }
}

// used for Spanish language version of the forms.
[data-culture="es-MX"] {
    .required::after {
        content: "obligatorio"
    }
}

.input-field {
    border-bottom: 1px solid #d1d1d1;
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0;
    background: none;
    width: 100%;
    font-family: $headingsFontFamily;
    @include font-size(16px);
    color: inherit;
    text-transform: uppercase;
    padding-bottom: $spacer/2;
    margin-bottom: $spacer;
    -webkit-appearance: none;

    .input--double-margin & {
        margin-bottom: $spacer*2;
    }

    @include media-breakpoint-up(md) {
        @include font-size(12px);
    }
}

.select-field {
    border-bottom: 1px solid #d1d1d1;
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0;
    background: none;
    width: 100%;
    font-family: $headingsFontFamily;
    @include font-size(16px);
    color: inherit;
    text-transform: uppercase;
    padding-bottom: $spacer/2;
    margin-bottom: $spacer;
    -webkit-appearance: none;
    position: relative;

    .select--double-margin & {
        margin-bottom: $spacer*2;
    }

    @include media-breakpoint-up(md) {
        @include font-size(12px);
    }
}

.input--select {
    position: relative;

    &:before {
        color: $softBlue;
        position: absolute;
        top: 5px;
        right: 0;
        @extend %iconStyleFas;
        @extend .fa-chevron-down;
    }
}

.input--double-margin {
    &.required {
        &:after {
            top: -34px;
        }
    }
}

.form-contact {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    align-items: center;
    margin-top: $spacer;

    span {
        font-weight: 700;
        font-size: 1.25rem;
    }
}

.form-contactPhone {
    &:before {
        @extend %iconStyleFas;
        @extend .fa-phone;
        color: $softBlue;
        padding-right: 0;
        font-size: 1rem;
        vertical-align: middle;
        position: relative;
        top: -2px;
    }
}

.form-expand {
    .form-expandButton {
        margin: 0 auto;
        cursor: pointer;
        padding-top: 18px;

        @include media-breakpoint-up(lg) {
            display: none;
        }

        .form-expandButtonCopy {
            display: block;
            margin: auto;
            height: auto;
            width: auto;

            &:before {
                @extend %iconStyleFas;
                @extend .fa-angle-down;
                position: absolute;
                right: 0;

                @media (min-width: 395px) {
                    right: 10%;
                }
            }
        }

        .form-closeButtonCopy {
            display: none;
            margin: auto;
            height: auto;
            width: auto;

            &:before {
                @extend %iconStyleFas;
                @extend .fa-angle-up;
                position: absolute;
                right: 10%;
            }
        }
    }

    .close {
        display: none;
        visibility: hidden;

        .form-expandButtonCopy {
            display: none;
        }

        .form-closeButtonCopy {
            display: block;
        }
    }
}

.expansive {
    display: none;

    @include media-breakpoint-up(lg) {
        display: block;
    }
}

.input {
    &.floating {
        position: relative;
        z-index: 0;

        label {
            @include font-size(13px);
            font-weight: 700;
            left: 0;
            letter-spacing: 1px;
            position: absolute;
            pointer-events: none;
            text-transform: uppercase;
            top: 10px;
            transition: all 250ms ease-in-out;
        }

        .input-field:focus~label{
                @include font-size(11px);
            top: -15px;
        }

        .select-field:focus ~ label , .select-field:not([value=""]):valid ~ label {
            @include font-size(11px);
            top: -15px;
        }

        &.is-floating {
            label {
                @include font-size(11px);
                top: -15px;
            }
        }
    }
}

// Form Autocomplete styles
.mapquestautocomplete {
    left: 15px;
    right: 20px;
    top: 25px;
    display: none;
    background-color: #fff;
    position: absolute;
    border-radius: 2px;
    border-top: 1px solid #d9d9d9;
    font-family: Arial, sans-serif;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    z-index: 999;

    &.active {
        display: block;
    }

    div {
        cursor: pointer;
        padding: 5px 20px;
    }
}

.mqpac {
    &-item {
        cursor: default;
        padding: 0 4px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 30px;
        text-align: left;
        border-top: 1px solid #e6e6e6;
        font-size: 11px;
        color: #999;

        &-selected,
        &-selected:hover {
            background-color: #ebf2fe
        }

        &-query {
            font-size: 13px;
            padding-right: 3px;
            color: #000
        }

        &:hover {
            background-color: #fafafa;
        }
    }

    &-matched {
        font-weight: 700;
    }

    &-icon {
        width: 15px;
        height: 20px;
        margin-right: 7px;
        margin-top: 6px;
        display: inline-block;
        vertical-align: top;
        background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
        background-size: 34px;

        &-search {
            background-position: -1px -1px;
        }

        &-marker {
            background-position: -1px -161px;
        }
    }

    &-placeholder {
        color: gray;
    }
}

.mqpac-item-selected .mqpac-icon-search {
    background-position: -18px -1px;
}

.mqpac-item-selected .mqpac-icon-marker {
    background-position: -18px -161px;
}

.mqhdpi .mqpac-icon {
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons_hdpi.png)
}
