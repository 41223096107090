.imageSpotlight {
    position: relative;
    padding-top: $spacer*4;
    padding-bottom: $spacer*4;
    @media (min-width: 992px) {
        padding-top: $spacer*4;
        padding-bottom: $spacer*6;
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background-color: $white;
        z-index: 0;
        @media (min-width: 992px) {
            height: 40%;
        }
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('/img/content-images/map-markers-mobile.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        z-index: 1;
        @media (min-width: 992px) {
            background-image: url('/img/content-images/map-markers-desktop.svg');
            background-position: bottom;
            background-size: auto 75%;
        }
    }
    &-image {
        position: relative;
        display: flex;
        justify-content: center;
        z-index: 2;
				> img {
					display: block;
					width: 100%;
					height: 100%;
				}
    }
}