.card {
    position: relative;
		display: inline-block;
		width: 100%;
    height: 100%;
    // margin-bottom: $spacer*2;
		padding: 40px;
		background-color: $white;
		box-shadow: 0px 0px 40px rgba(0,0,0,0.15);
		.fillSoftBlue & {
			box-shadow: 9px 9px 10px rgba(72,148,178,0.15);
		}
		&--bundle,
		&--lighting {
			@media (min-width: 768px) {
				padding: 72px;
			}
			.card-body {
				p {
					font-size: 18px;
				}
			}
			.card-footer {
				h5 {
					font-size: 12px;
					text-transform: uppercase;
				}
			}
			.card-list {
				font-size: 18px;
			}
			.card-listItem {
				position: relative;
				&:before {
					left: -40px !important;
					top: 10px;
				}
			}
		}
    &-inner {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    &-header {
        flex: 0 1 auto;
        display: flex;
        align-items: center;
        margin-bottom: $spacer;
        i {
            display: inline-block;
            font-size: 24px;
            &.fa-plane {
                transform: rotate(-45deg) translateY(-5px);
            }
        }
        span {
            color: $navyBlue;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 16px;
            text-transform: uppercase;
        }
    }
    &-body {
        flex: 1 0 auto;
        h4 {
            color: $navyBlue;
            font-size: 22px;
            line-height: 27px;
            text-transform: uppercase;
        }
        p {
            font-size: 13px;
        }
    }
    &-footer {
        flex: 0 1 auto;
        min-height: 97px;
        &--noMin {
            min-height: auto;
        }
        h5 {
            font-size: 13px;
        }
        p {
            margin-bottom: 0;
            font-size: 13px;
        }
    }
    &-list {
        position: relative;
        margin-bottom: 0;
        list-style: none;
        counter-reset: orange-numbers;
        font-size: 13px;
        .card-listItem {
            counter-increment: orange-numbers;
            padding: $spacer*0.25 0;
            &:before {
                position: absolute;
                left: 0;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                content: counter(orange-numbers);
                width: 20px;
                height: 20px;
                background-color: $flame;
                border-radius: 50%;
                color: $white;
            }
        }
        &--checks {
            counter-reset: orange-checks;
            margin-bottom: $spacer*1.5;
            .card-listItem {
                counter-increment: orange-checks;
                &:before {
                    content: '\f00c';
                    font-family: 'Font Awesome 5 Free';
                    font-size: 0.75rem;
                    font-weight: 900;
                }
            }
        }
    }
}


//service card and MISC

.service__title {
    color: $white;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 1rem;
}
.service__subtitle {
    color: $white;
    text-align: center;
    color: #D1D1D1;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-transform: none;
}

.service-card {
    background: $navyBlue;
    margin-bottom: 3rem;
    border: 1px solid #59BFE6;
    display: flex;
    position: relative;
    height: calc(100% - 4rem);
    margin-bottom: 4rem;
    @include media-breakpoint-up(lg) {
        height: calc(100% - 5rem);
        margin-bottom: 5rem;
    }

}

.service-card__logo-box {
    background: $white;
    //display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 1rem;
    width: 140px;
    min-width: 140px;
    height: 100%;
    position: relative;
    &--black {
        background: $black;

    }
    @include media-breakpoint-up(lg) {
        min-width: 173px;
        min-height: 99px;
    }
}
.service-card__content {
    padding: 1.75rem 1.75rem 1.75rem 1.75rem;
}
.service-card__logo-box-image {
    //max-height: 50px;
    width: 80px;
    margin: 0 auto;
    //width: 80px;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @include media-breakpoint-up(lg) {
        //max-height: 62px;
        //max-width: 136px;
        //width: 90px;
        width: 90px;
    }
}
.service-card__title {
    color: $white;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 22px;
}
.service-card__text {
    color: $white;
    margin-bottom: 0;
    font-size: 14px;
}
a.service-card__cta {
    margin-bottom: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: $softBlue;
    text-decoration: none;
    position: absolute;
    right: 0;
    bottom: -26px;
    &:hover {
        text-decoration: underline;
    }
}

.service-card--featured {
    flex-direction: column;
    box-shadow: 0px 5px 20px rgba(89, 191, 230, 0.4);
    padding-bottom: 1.5rem;
    height: calc(100% - 5rem);
    margin-bottom: 5rem;
    .service-card__logo-box {
        height: 130px;
        width: 100%;
    }
    .service-card__logo-box-image {
        max-height: none;
        max-width: none;
        height: auto;
        width: 160px;
        
    }
    .service-card__title {
        font-size: 18px;
        line-height: 21.94px;
    }
    .service-card__text {
        line-height: 24px;

    }
    .service-card__content {
        padding: 1.75rem 1.75rem 1rem 1.75rem;
    }
    a.service-card__cta {
        background-color: $softBlue;
        color: $white;
        padding: 0.5rem 1rem;
        right: 50%;
        margin-right: -138px;
        min-width: 276px;
        min-height: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: -19px;
        &:hover {
            background: $flame;
            text-decoration: none;
        }
    }

}

.cta-card-1 {
    background: #381717;
    border: 1px solid #59BFE6;
    box-sizing: border-box;
    color: white;
    position: relative;
    margin-bottom: 3rem;
    padding: 4rem 1rem 1rem 1rem;
    text-align: center;
    &::before {
        content: '';
        background-image: url('/img/content-images/streaming-logos/tickets-icon.png');
        background-repeat: no-repeat;
        position: absolute;
        top: -23px;
        width: 66px;
        height: 67px;
        background-size: 100%;
        left: 50%;
        margin-left: -20px;
    }
    .cta-card-1__title {
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        text-transform: none;
        margin-bottom: 0.5rem;
        color: $flame;
    }
    .cta-card-1__subtitle {
        color: $softBlue;
        font-size: 1.3125rem;
        font-weight: 600;
        text-transform: none;
        
    }
    a.cta-card-1__cta {
        margin-bottom: 0;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: $softBlue;
        text-decoration: none;
        position: absolute;
        background-color: $flame;
        color: $white;
        padding: 0.5rem 1rem;
        right: 50%;
        margin-right: -138px;
        min-width: 276px;
        min-height: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: -19px;
        &:hover {
            background: $softBlue;
            text-decoration: none;
        }
    }
}

.cta-card-2 {
    border: 1px solid #59BFE6;
    box-sizing: border-box;
    display: flex;
    color: $white;
    background-color: $navyBlue;
    margin-bottom: 3rem;
    .cta-card-2__media {
        background-color: $softBlue;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        width: 130px;

    }
    .cta-card-2__media-image {
        width: 48px;
        margin: 0 auto;

    }
    .cta-card-2__media-body {
        padding: 1rem 1.25rem;
    }
    .cta-card-2__title {
        margin-bottom: 0;
        font-weight: bold;
        font-size: 16px;
        color: $white;
    }
    .cta-card-2__text {
        color: $white;
        margin-bottom: 0;
        font-size: 14px;
    }
    a.cta-card-2__cta {
        margin-bottom: 0;
        font-size: 14px;
        color: $flame;
        font-weight: 600;
    }
}