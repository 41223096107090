// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
.footerMain {
    margin: auto;
    color: $white;
    .footerMain-inner {
        text-align: center;
        @include media-breakpoint-up(lg) {
            text-align: left;
        }
    }
}

.footerMain--landing {
    .footerMain-contactDetails {
        margin-bottom: $spacer*2;
    }
    .footerMain-form {
        padding-top: $spacer*2;
        padding-bottom: $spacer;
        background-color: palette(softBlue, 50);
        z-index: 1;
        button[type="submit"] {
            width: 100%;
            background-color: $flame;
            border: 0;
            border-radius: 5px;
            color: $white;
            font-size: 0.75rem;
            font-weight: 700;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to right top, rgba($white, 0.0) 50%, rgba($white, 0.1) 50%);
            }
            &:after {
                content: '\f054';
                font-family: 'Font Awesome 5 Free';
                margin-left: $spacer*0.5;
            }
        }
    }
    .footerMain-formWrapper {
        padding: $spacer*2.5 $spacer*1.5 $spacer*1.5;
        background-color: $white;
        border-radius: 6px;
        box-shadow: 0 0 20px rgba(#094B83, 0.23);
        @media (min-width: 992px) {
            padding: $spacer*2.5 $spacer*1.5;
        }
    }
    .footerMain-formTitle {
        margin-bottom: $spacer*1.25;
        font-size: 26px;
        text-align: center;
        @media (min-width: 992px) {
            margin-bottom: $spacer*2;
        }
    }
    .footerMain-formCopy {
        text-align: center;
        font-size: 1rem;
    }
    .footerMain-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: $spacer*2;
        @media (min-width: 768px) {
            flex-direction: row;
            justify-content: center;
        }
        @media (min-width: 992px) {
            margin-bottom: $spacer*3;
        }
    }
    .footerMain-listItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 0.875rem;
        font-weight: 700;
        @media (min-width: 768px) {
            flex-direction: row;
            flex: 0 1 auto;
            justify-content: flex-start;
            width: auto;
        }
        @media (min-width: 992px) {
            padding-left: $spacer;
            padding-right: $spacer;
        }
    }
    .footerMain-listIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        margin-bottom: $spacer*0.5;
        background-color: $softBlue;
        border-radius: 50%;
        color: $white;
        @media (min-width: 768px) {
            margin-bottom: 0;
            margin-right: $spacer*0.5;
        }
        @media (min-width: 992px) {
            margin-right: $spacer;
        }
    }
    .footerMain-listCopy {
        @media (min-width: 768px) {
            margin-right: $spacer;
        }
        @media (min-width: 992px) {
            margin-right: $spacer*1.75;
        }
    }
    .footerMain-listDots {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: $spacer 0;
        @media (min-width: 768px) {
            flex-direction: row;
        }
        span {
            width: 4px;
            height: 4px;
            margin-bottom: $spacer*0.75;
            background-color: rgba($softBlue, 0.5);
            border-radius: 50%;
            @media (min-width: 768px) {
                margin-bottom: 0;
                margin-right: $spacer*0.75;
                &:last-child {
                    display: none;
                }
            }
            @media (min-width: 992px) {
                &:last-child {
                    display: block;
                }
            }
        }
    }
    .footerMain-main {
        padding-top: $spacer*5;
        z-index: 0;
    }
    .footerMain-baseCopyright {
        margin-bottom: $spacer*1.25;
        padding-left: $spacer*2.5;
        padding-right: $spacer*2.5;
        @media (min-width: 992px) {
            padding-left: 0
        }
    }
    .footerMain-copyright {
        font-size: 14px;
    }
}

.footerMain-inner {
    max-width: $maxWidth;
    margin-left: auto;
    margin-right: auto;
    padding: $spacer*2 $spacer;
    @include media-breakpoint-up(md) {
        padding: $spacer*3 $spacer;
    }
}

.footerMain-nav {
    padding-left: $spacer;
    margin-bottom: $marginBase;
    @include media-breakpoint-up(md) {
        border-bottom-width: 0;
    }
}

.footerMain-logoLink {
    padding-bottom: $spacer*2;
    img {
        width: 80%;
        max-width: 300px;
        height: auto;
    }
    .footerMain--landing & {
        padding-bottom: 0;
        img {
            width: 268px;
        }
    }
}

.footerMain-contactDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media-breakpoint-up(md) {
        border-right: 2px solid #29295a;
        .footerMain--landing & {
            margin-bottom: $spacer*2;
            border-right: none;
        }
    }
    a {
        color: $white;
        text-decoration: none;
        &:hover {
            text-decoration: none;
            color: $softBlue;
        }
    }
    span {
        margin-bottom: $spacer;
        align-items: baseline;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        @include media-breakpoint-down(md) {
            align-items: flex-start;
             :before {
                display: none;
            }
        }
    }
    .footer-contactPhone {
        &:before {
            @extend %iconStyleFas;
            @extend .fa-phone;
            color: $softBlue;
            padding-right: $spacer;
            font-size: 1rem;
        }
    }
    .footer-contactAddress {
        &:before {
            @extend %iconStyleFas;
            @extend .fa-map-marker-alt;
            color: $softBlue;
            padding-right: $spacer;
            font-size: 1rem;
        }
    }
    @include media-breakpoint-up(lg) {
        align-items: flex-start;
    }
}

.footerMain-nav {
    margin-bottom: 0;
    .row {
        margin-right: 0px;
    }
}

.footerMain-navList {
    list-style: none;
    column-count: 2;
    width: 100%;
    list-style-position: inside;
    padding: 0;
    line-height: 3rem;
    border-top: 1px solid palette(navyBlue, 400);
    border-bottom: 1px solid palette(navyBlue, 400);
    padding: $spacer*2 0;
    @include media-breakpoint-up(sm) {
        border: none;
        padding-top: 0;
    }
    @include media-breakpoint-up(lg) {
        column-count: 2;
    }
}

.footerMain-navItem {}

.footerMain-navLink {
    font-family: $headingsFontFamily;
    text-decoration: none;
    color: inherit;
    display: $softBlue;
    &:hover {
        text-decoration: none;
        color: inherit;
        color: $softBlue;
        @include media-breakpoint-up(sm) {
            border-bottom-width: 0;
        }
        @include media-breakpoint-up(md) {
            padding-top: $spacer/4;
            padding-bottom: $spacer/4;
        }
    }
}

.footerMain-aboutCopy {
    color: palette(gray, 200);
    font-size: 0.875rem;
}

.footerMain-socialNavList {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    @include media-breakpoint-up(lg) {
        justify-content: start;
    }
}

.footerMain-socialLink {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $spacer;
    font-size: 0;
    color: inherit;
    padding: $spacer;
    margin-right: $spacer;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    @extend %iconStyleFas;
    background-color: palette(navyBlue, 800);
    color: $softBlue;
    text-decoration: none;
    &[class^='fa-'],
    &[class*=' fa-'] {
        &:before {
            font-size: 24px;
            margin-right: 0;
        }
    }
    &:hover {
        background-color: $linkColorHoverBase;
        text-decoration: none;
        color: $white;
        transform: scale(1.1);
    }
}

.footerMain-base {
    background-color: palette(navyBlue, 800);
    color: $white;
}

.footerMain-baseInner {
    max-width: $maxWidth;
    margin-left: auto;
    margin-right: auto;
    padding: $spacer 0;
    text-align: center;
}

.footerMain-baseCopy {
    margin-bottom: 0;
    font-size: 1rem;
}

.footerMain-baseNavList {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    @include media-breakpoint-up(lg) {
        display: inline-block;
    }
    .footerMain-baseNavLink {
        color: $white;
        text-decoration: underline;
        &:hover {
            color: $softBlue;
        }
    }
}

.footerMain--landing {
    .footerMain-form {
        color: black;
    }
}