// -----------------------------------------------------------------------------
// This file contains all styles related to the featured content component.
// -----------------------------------------------------------------------------

.featureTile {
    display: flex;
    flex-flow: row wrap;
		align-content: stretch;

    &--flex {
        align-items: flex-start;
        @media only screen and (min-width: 992px) {
            justify-content: space-between;
        }
        .featureTile-media {
            order: 1;
            @media only screen and (min-width: 992px) {
                order: 2;
								flex: 0 0 60%;
								max-width: 60%;
                padding: $spacer*2;
            }
        }
        .featureTile-item {
            order: 2;
            font-size: 14px;
            @media only screen and (min-width: 992px) {
                order: 1;
								flex: 0 0 40%;
								max-width: 40%;
                padding: $spacer*2;
            }
        }
    }

    &--flexReverse {
        .featureTile-media {
            @media only screen and (min-width: 992px) {
                order: 1;
            }
        }
        .featureTile-item {
            @media only screen and (min-width: 992px) {
                order: 2;
            }
        }
    }

    &--floatLeft {
		@media only screen and (min-width: 992px) {
			display:block;
			.featureTile-media {
				float: left;
				max-width: 66.66667%;
				margin-right: 30px;
				margin-bottom: 1rem;
			}
			.featureTile-item {
				display: block;
			}
		}
	}
	&--floatRight {
		@media only screen and (min-width: 992px) {
			display:block;
			.featureTile-media {
				float: right;
				max-width: 66.66667%;
				margin-left: 30px;
			}
			.featureTile-item {
				display: block;
			}
		}
	}

    .featureTile-item {
        @include media-breakpoint-up(lg) {
            margin-bottom: 20px;
            height: calc(100% - 20px);
        }
    }
}

.featureTile-item {
    display: flex;
		flex-direction: column;
		align-items: flex-start;
    margin-bottom: $spacer;
    margin-top: $spacer;

    @include media-breakpoint-up(lg) {
			width: 100%;
			height: 100%;
			margin-top: 0;
			margin-bottom: 0;
		}
		
		&--article {
			min-height: auto !important;
		}
		&--fullHeight {
			height: 100% !important;
			font-size: 14px;
		}

		&Cta {
			margin: 0 auto;
			padding-left: 30px;
			padding-right: 30px;
			box-shadow: none;
			@include media-breakpoint-up(lg) {
				margin: 0;
				padding-left: 50px;
				padding-right: 50px;
			}
		}

		p, ul {
			width: 100%;
		}
}

a.featureTile-item {
    color: inherit;
    text-decoration: none;
    &:hover {
        color: inherit;
    }

    * {
        color: inherit;
        text-decoration: none;
    }

    & *:hover {
        color: inherit
    }
}

.featureTile-figure {
	position: relative;
	width: 100%;
	overflow: hidden;
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		height: 100%;
	}
	&::after {
		content: '';
		display: block;
		padding-top: calc(400 / 600 * 100%);
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			display: none;
		}
	}
	// @include media-breakpoint-up(md) {
	// 	height: 100%;
	// }
	img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: auto;
	}
}

.featureTile-providerLogo {
	display: flex;
	justify-content: flex-start;
	margin-bottom: $spacer*2;
	svg {
		height: 30px;
		max-height: 30px;
	}
}

.featureTile-icon {
    font-size: 1.5rem;
    padding-bottom: $spacer;
}

.featureTile-header {
    margin-bottom: auto;
}

.featureTile-heading {
	width: 100%;
}

.featureTile-copy {
	width: 100%;
    .fillCharcoal50 & {
        color: $black;
    }
    .bglogoFill-navyBlue & {
        color: $white;
    }
    p {
        color: inherit;
    }
}

.featureTile-link {
    align-items: center;
    width: auto;
    margin-top: auto;
    margin-right: auto;

    &:hover {
        text-decoration: none;
        color: inherit;
    }
}

.featureTile-media {
	position: relative;
    width: 100%;
    // height: 100%;
	&::after {
		content: '';
		display: block;
		padding-top: calc(400 / 600 * 100%);
	}

    img {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
        width: 100%;
        height: auto;
    }

    &--video {
        padding: 42.5% 0 0 0;
        position: relative;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
		}
		
		&--offset {
			width: 100%;
			height: 100%;
			min-height: 400px;
			background-size: 0 0;
			background-position: 0 0;
			background-repeat: no-repeat;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 140%;
				height: 100%;
				max-height: 500px;
				background-image: inherit;
				background-size: cover;
			}
		}
}

.gray-bg {
	position: absolute;
	display: none;
	top: 0;
	right: 0;
	width: 40%;
	height: 100%;
	background: palette(gray,100);
	z-index: -1;
	@include media-breakpoint-up(lg) {
		display: block;	
	}
	img {
		position: absolute;
		bottom: -5%;
		left: -7%;
	}
}

.featureTile-group {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
    margin-top: -$spacer * 2.5;

    .featureTile-item {
        margin: ($spacer * 2.5) ($spacer * 1.25) 0;
        height: auto;
        align-self: stretch;
        padding: 1.25rem;
        flex: 0 0 calc(100% - #{$spacer * 2.5});

        @media (min-width: 850px) {
            flex: 0 0 calc(50% - #{$spacer * 2.5});
        }

        @media (min-width: 1075px) {
            flex: 0 0 calc(32% - #{$spacer * 2.5});
        }

        @media (min-width: 1250px) {
            flex: 0 0 calc(25% - #{$spacer * 2.5});
        }
    }
}


.featureTile-media.featureTile--tall, .featureTile-figure.featureTile--tall{
	&::after {
		padding-top: 100%;
	}
}
