// -----------------------------------------------------------------------------
// This file contains all styles related to the mediaTile component.
// -----------------------------------------------------------------------------

.mediaTile {
    background: transparent;
    margin: 10px auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: $spacer;
    height: 100%;
    border: 7px solid transparent;
    transition: border 250ms ease-in-out, background 250ms ease-in-out;
    color: inherit;
    
    &:hover {
        border: 7px solid palette(charcoal, 50);
        background: $white;

        .mediaTile-link {
            opacity: 1;
            
        }
		}
		
		&--alt {
			margin-top: 0;
			padding: 0;
			border: 0;
			&:hover {
				border: 0;
        background: $white;
			}
		}

		&--small {
			flex-direction: row;
			height: auto;
			margin-top: 0;
			padding: 0;
			margin-bottom: $spacer;
			border: 0;
			&:hover {
				border: 0;
        background: $white;
			}
			.mediaTile-photo {
				flex: 1 0 119px;
				max-width: 119px;
				height: 119px;
				margin-right: $spacer;
				background-position: center center;
				background-size: cover;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.mediaTile-heading {
				margin-bottom: 0;
			}
		}
}

a.mediaTile {
    color: inherit;
    text-decoration: none;
    &:hover {
        color: inherit;
    }

    * {
        color: inherit;
        text-decoration: none;
    }

    & *:hover {
        color: inherit
    }

    .mediaTile-indicator {
        padding: $spacer/3 $spacer;
        position: absolute;
        right: 50px;
        top: 35px;
        background-color: $navyBlue;
        color: $white;
        font-size: .875rem;
    }
}

.mediaTile-link {
    align-items: center;
    width: auto;
    margin-right: auto;
    margin-top: auto;
    opacity: 1;
    transition: opacity 250ms ease-in-out;
    @include media-breakpoint-up(lg) {
        opacity: 0;
    }
}

.mediaTile-photo {
    margin-bottom: $spacer*2;
		height: 250px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.mediaTile-heading {
    color: $black;
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: auto;
    a {
        color: $black;
        text-decoration: none;
        &:hover {
            color: $softBlue;
        }
    }
    
}

.mediaTile-copy {
    padding: $spacer 0;
		color: $black;
}
