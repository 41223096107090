////////////////////////////////////////////
//			Global Styles
////////////////////////////////////////////
html {
	 &.no-scroll {
			body {
				 overflow: hidden!important;
			}
	 }
}

[data-popup-trigger] {
	cursor: pointer !important;
}

.pageWrap {
	transition: filter .25s ease-out;
	 &.is-blurred {
			filter: blur(3px);
	 }
}

////////////////////////////////////////////
//			Specific Popup Instances
////////////////////////////////////////////
#popup_cordCutting {
	margin: 0;
	padding: 0;
	&::after {
		display: block;
		content: '';
		padding-top: 56.25%;
		pointer-events: none;
	}
	& > * {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
	iframe,
	video {
		width: 100%;
		height: 100%;
	}
}

////////////////////////////////////////////
//			General Popup Styles
////////////////////////////////////////////
[data-popup-trigger] {
	 cursor: pointer;
}

.popup {
	 position: fixed;
	 z-index: 100000;
	 top: 0;
	 right: 0;
	 bottom: 0;
	 left: 0;
	 display: block;
	 width: 100vw;
	 height: 100vh;
	 transform: translateZ(2px);
	 -webkit-transform: translate3d(0,0,0);
	 &-data {
			display: none;
	 }
	 &-overlay {
		position: absolute;
		z-index: 2;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		background: rgba(0,0,0, .75);
		overflow-y: scroll;
		overflow-x: hidden;
		padding: $spacer 0;
	 }
	 &-content {
		margin-bottom: 50px;
		position: relative;
		z-index: 2;
		display: flex;
		overflow: hidden;
		width: 750px;
		max-width: 95%;
		height: auto;
		border: 2px solid $white;
		background-color: $white;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;
		box-shadow: 0 0 10px rgba(0,0,0, .5);
		margin: auto 0;
		@media (min-width: 768px) {
			max-width: 70vw;
		}
	 }
	 &-close {
			position: absolute;
			z-index: 10;
			top: 10px;
			right: 10px;
			height: 25px;
			width: 25px;
			color: $softBlue;
			cursor: pointer;
		&:before,
		&:after {
			position: absolute;
			content: '';
			display: block;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			height: 5px; 
			width: 20px;
			background: $softBlue;
			transform: rotate(45deg);
		}
		&:after {
			transform: rotate(-45deg);
		}
	 }
}

[id^="popup_"] {
	display: none;
}

.popup-content > [id^="popup_"] {
	position: relative;
	z-index: 1;
	width: 100%;
	margin: $spacer;
	padding: $spacer*2 0;
	display: block;
	> aside {
		position: absolute;
		top: -20px;
		right: 30px;
		text-transform: uppercase;
		font-size: .8rem;
		color: palette(charcoal, 800);
		a {
			margin-left: 5px;
			text-decoration: underline;
			color: palette(charcoal, 800);
		}
	 }
	 h5 {
		color: palette(charcoal, 600);
		& + .form {
			margin-top: 15px;
		}
	 }
	 p {
		margin-bottom: 10px;
		color: palette(charcoal, 600);
		// &:last-of-type {
		// 		margin-bottom: 20px;
		// }
	 }
	 .button-block {
		display: flex;
	 }
	 .button {
		min-width: 100px;
		text-align: center;
		color: palette(charcoal, 600);
		&:before {
				color: palette(charcoal, 600);
		}
		&:hover,
		&:after {
				color: $white;
		}
	 }
}

.popup-mainImg{
    max-width: 500px;
    width: 100%;
    margin: 0 auto $spacer*2;
}

.popup-form{
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
}
