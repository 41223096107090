.freecontent {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	ul,
	ol,
	dl,
	aside,
	blockquote,
	img,
	figure {
		margin: 0 0 25px;
	}

	strong {
		font-weight: 900;
	}
}